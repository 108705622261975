import React, { SyntheticEvent, useState } from "react";
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { Card, CardBody } from 'reactstrap';
import { CheckCircle, XCircle } from "react-feather";

import { NotificationType } from "../../types";
import ModalUpdateNotificationType from "./AdminView/ModalUpdateNotificationType";


const columns: ColumnDescription<NotificationType>[] = [
	{ dataField: 'notification_type_name', text: 'Name', sort: true,
	},
	{ dataField: 'recipient_type', text: 'Recipient', sort: true,
	},
	{ dataField: 'should_send_email', text: 'Email activated', sort: true, 
		formatter: (cell: boolean | null, row: NotificationType) => cell ? <CheckCircle color="green" /> : <XCircle color="red" />
	},
	{ dataField: 'email_template', text: 'Template', sort: true,
	formatter: (cell: any, row: NotificationType) => cell ? <CheckCircle color="green" /> : <XCircle color="red" />
	},
	// { dataField: 'observations', text: 'Observations', sort: true },
];
type Props = {
	data: NotificationType[];
	refetch: () => void;
};
const NotificationTypesGrid = ({ data, refetch }: Props) => {
	const [selectedNotificationType, setSelectedNotificationType] = useState<NotificationType | null>(null);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const toggleModal = () => setModalIsOpen(!modalIsOpen);

	const rowEvents = {
        onClick: (e: SyntheticEvent, row: NotificationType, rowIndex: number) => {
			setSelectedNotificationType(row);
			toggleModal();
        }
    }
    return (
        <>
			<ModalUpdateNotificationType notificationType={selectedNotificationType} isOpen={modalIsOpen} toggleModal={toggleModal} refetch={refetch}/>
            <Card>
                <CardBody>
                    <div className="table-responsive mainJobsTable">
                        <BootstrapTable
                            keyField="notification_type_id"
                            data={data}
                            columns={columns}
							rowEvents={rowEvents}
                        />
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default NotificationTypesGrid;