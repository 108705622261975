
import { all, call, put, takeEvery, fork, select } from "redux-saga/effects";
import Bugsnag from "@bugsnag/js";
import Swal from "sweetalert2";

import { getCurrentUsdEurExchangeRate, mapContractorFn, mapContractorMonthFn } from './utils';
import axiosClient from "../../helpers/Axios";
import { editSelectedCandidateError, editSelectedCandidateSuccess, getAllContractorsError, getAllContractorsSuccess, getClientsError, getClientsSuccess, 
    getCmRecordsFailed, 
    getCmRecordsSuccess, 
    getExchangeRatesFailed, getExchangeRatesSuccess, getPossibleInvoiceStatusesError, 
    getPossibleInvoiceStatusesSuccess, initialLoadAdminDbFailed, initialLoadAdminDbSuccess, initMonthEndFailed, 
    initMonthEndSuccess, updateExchangeRateFailed, updateExchangeRateSuccess 
} from "./actions";
import { EDIT_SELECTED_CANDIDATE, GET_ALL_CONTRACTORS, GET_CLIENTS, GET_CONTRACTOR_MONTH_RECORDS, GET_EXCHANGE_RATES, 
    GET_POSSIBLE_INVOICE_STATUSES, INITIAL_LOAD_ADMIN_DB, INIT_MONTH_END, UPDATE_EXCHAGE_RATE 
} from "./constants";
import { saveAudit } from "../../helpers/audit";


const getInitState = async () => {
    const [
        contractors, clients, invoiceStatuses, rates, contractorMonths, 
        processedMonths, currentUsdEurRate, countries
    ] = await Promise.all([
        axiosClient.get(`/contractors`),
        axiosClient.get(`/clients?is_active=true`),
        axiosClient.get(`/admin/invoiceStatus`),
        axiosClient.get(`/rates?from_currency=EUR&to_currency=USD`),
        axiosClient.get(`/contractormonths`),  //GET WITHOUT MONTH YEAR FILTER, MAYBE CHANGE LATER
        axiosClient.get(`/admin/processedMonths`),
        getCurrentUsdEurExchangeRate(),
		axiosClient.get(`/countries/distinct`),
    ]);
    return {
        contractors, clients, invoiceStatuses, rates, contractorMonths, 
        processedMonths, currentUsdEurRate, countries
    }
}


function* initState(){
    try {
        const { contractors, clients, invoiceStatuses, rates, contractorMonths, 
            processedMonths, currentUsdEurRate, countries
        } = yield call(getInitState);
        const contractorsMapped = contractors.data.map( mapContractorFn );
        const cms = contractorMonths.data.map( mapContractorMonthFn );
        const data = {
            clients: clients.data,
            invoiceStatuses: invoiceStatuses.data,
            rates: rates.data,
            contractors: contractorsMapped, 
            contractorMonths: cms,
            processedMonths: processedMonths.data,
            currentUsdEurRate,
			countries: countries.data,
        };
        yield put( initialLoadAdminDbSuccess( data ) );
    } catch (error) {
        console.log('Error on initial load', error, error.response, error.response?.data);
		Bugsnag.notify(error);
        yield put( initialLoadAdminDbFailed(`Error on initial load, please refresh the page`) );
    }
}
function* getAllContractorsFromBackEnd(){
    try{
        let response = yield call(axiosClient.get, `/contractors`);
        if( response.data ){
            const contractors = response.data.map( mapContractorFn );
            yield put( getAllContractorsSuccess( contractors ) );
        }else{
            yield put( getAllContractorsError('Error getting contractors! Please try again.') );
        }
    }catch(error){
        console.log('Error on getAllContractorsFromBackEnd', error, error.response, error.response?.data);
		Bugsnag.notify(error);
        let errorToShow = 'Server error! Please try again.';
        yield put( getAllContractorsError(errorToShow) );
    }
}
function* getClientsFromBackend(){
    try {
        let response = yield call(axiosClient.get, `/clients`);
        if(response.data){
            yield put( getClientsSuccess( response.data ) );
        }else{
            yield put( getClientsError('Error getting clients, please try again!') );
        }
    } catch (error) {
        console.log('Error getting clients', error, error.response, error.response?.data);
		Bugsnag.notify(error);
        let errorToShow = 'Error getting clients! Please try again.';
        yield put( getClientsError(errorToShow) );
    }
}
function* getInvoiceStatusesFromBackend(){
    try {
        let response = yield call(axiosClient.get, `/admin/invoiceStatus`);
        if(response.data){
            yield put( getPossibleInvoiceStatusesSuccess( response.data ) );
        }else{
            yield put( getPossibleInvoiceStatusesError('Error getting invoiceStatus, please try again!') );
        }
    } catch (error) {
        console.log('Error getting invoiceStatus', error, error.response, error.response?.data);
		Bugsnag.notify(error);
        let errorToShow = 'Error getting invoiceStatus! Please try again.';
        yield put( getPossibleInvoiceStatusesError(errorToShow) );
    }
}
function* initMonthEnd({payload}){
    try{
        let response = yield call(axiosClient.post, `/admin/monthEnd`, payload);
        if(response?.data?.data){
			yield call(saveAudit, {
				action_id: 1,
				after_data: {...response.data.data},
				before_data: payload,
				datetime_start: new Date().toISOString(),
				observations: 'Month end initted',
				error: null,
				has_error: false,
			});
            yield put( initMonthEndSuccess(response.data.data) );
        }else{
            yield put( initMonthEndFailed('Error starting month end process, try again.') )
        }
    }catch(error){
        console.log('Error starting month end', error, error.response, error.response?.data);
		Bugsnag.notify(error);
        yield put( initMonthEndFailed('Error starting month end process, try again.') )
    }
}

function* getExchangeRatesFromBackend({payload}){
	let endpoint = `/rates?from_currency=EUR&to_currency=USD`;
	if (payload.month && payload.year) {
		endpoint = `${endpoint}&effective_date=${payload.year}-${payload.month}-01`;
	}
    try {
        let response = yield call(axiosClient.get, endpoint);
        yield put( getExchangeRatesSuccess(response.data) );
    } catch (error) {
        console.log('Error getting exchange rates', error, error.response, error.response?.data);
		Bugsnag.notify(error);
        yield put( getExchangeRatesFailed('Error getting exchange rates, please try again') );
    }
}
function* updateExchageRateOnBackend({payload}){
    const updatedRate = {...payload};
    const rateId = payload.rate_id;
    delete updatedRate.rate_id;
    updatedRate.is_active = true;
    try {
        let response = yield call(axiosClient.put, `/rate/${rateId}`, updatedRate);
        yield put( updateExchangeRateSuccess(response.data) );
    } catch (error) {
		console.log('Error updating exchange rates', error, error.response, error.response?.data);
		Bugsnag.notify(error);
        yield put( updateExchangeRateFailed(`Error while updating the exchange rates, please try again`) );
    }
}
function* getCmRecordsFromBackend({payload}){
	const month = payload.month;
	const year = payload.year;
    try {
		let endpoint = `/contractormonths`;
		if (month && year) {
			endpoint = `/contractormonths?month=${month}&year=${year}`;
		}
        let response = yield call(axiosClient.get, endpoint);
        if(response.data){
            const cms = response.data.map( mapContractorMonthFn );
            yield put( getCmRecordsSuccess(cms) );
        }else{
            yield put( getCmRecordsFailed('Error getting contractor month records, please try again!') );
        }
    } catch (error) {
        console.log('Error getting contractor month records', error, error.response, error.response?.data);
		Bugsnag.notify(error);
        let errorToShow = 'Error getting contractor month records! Please try again.';
        yield put( getCmRecordsFailed(errorToShow) );
    }
}

const getOldCandidateValues = state => state.AdminDashboard.selectedCandidate;

function* editSelectedCandidateOnBackend({ payload }){
	const oldCandidate = yield select(getOldCandidateValues);
	try {
        let response = yield call(axiosClient.put, `/candidate/${payload.id}`, payload.data);
        yield put(editSelectedCandidateSuccess(response.data));
		yield call(saveAudit, {
			action_id: 53,
			after_data: { ...response.data },
			before_data: { ...oldCandidate },
			datetime_start: new Date().toISOString(),
			observations: `Updated candidate details of ${oldCandidate.candidate_first_name} ${oldCandidate.candidate_last_name}.`,
			error: null,
			has_error: false,
		});
    } catch (error) {
		yield put(editSelectedCandidateError(error));
		console.log('Error updating candidate', error, error.response, error.response?.data);
		Bugsnag.notify(error);
        Swal.fire({
			title: 'Error updating candidate',
			text: 'Error updating candidate, please try again',
			icon: 'error',
		}).then(() => {
			window.location.reload();
		});
    }
}

export function* watchInitState(){
    yield takeEvery(INITIAL_LOAD_ADMIN_DB, initState);
}
export function* watchGetAllContractors(){
    yield takeEvery(GET_ALL_CONTRACTORS, getAllContractorsFromBackEnd);
}
export function* watchGetClients(){
    yield takeEvery(GET_CLIENTS, getClientsFromBackend);
}
export function* watchGetInvoiceStatuses(){
    yield takeEvery(GET_POSSIBLE_INVOICE_STATUSES, getInvoiceStatusesFromBackend);
}
export function* watchInitMonthEnd(){
    yield takeEvery(INIT_MONTH_END, initMonthEnd)
}
export function* watchGetExchangeRates(){
    yield takeEvery(GET_EXCHANGE_RATES, getExchangeRatesFromBackend);
}
export function* watchUpdateExchangeRates(){
    yield takeEvery(UPDATE_EXCHAGE_RATE, updateExchageRateOnBackend);
}
export function* watchEditCandidate(){
    yield takeEvery(EDIT_SELECTED_CANDIDATE, editSelectedCandidateOnBackend);
}
export function* watchGetCmRecords(){
    yield takeEvery(GET_CONTRACTOR_MONTH_RECORDS, getCmRecordsFromBackend);
}
function* adminViewSaga(){
    yield all([ fork(watchGetAllContractors),
        fork(watchGetClients), fork(watchGetInvoiceStatuses), 
        fork(watchInitMonthEnd) , fork(watchGetExchangeRates), fork(watchUpdateExchangeRates),
        fork(watchInitState), fork(watchGetCmRecords),
		fork(watchEditCandidate),
    ]);
}
export default adminViewSaga;