import {
    GET_CONTRACTOR_MONTH_DATA_SUCCESS,
    GET_CONTRACTOR_MONTH_DATA_FAILED,
    SAVE_CONTRACTOR_MONTH_DATA,
    SAVE_CONTRACTOR_MONTH_DATA_FAILED,
    SAVE_CONTRACTOR_MONTH_DATA_SUCCESS,
    GET_CONTRACTOR,
    GET_CONTRACTOR_SUCCESS,
    SET_CONTRACTOR_LOADER,
	UPDATE_CONTRACTOR_DATA,
	UPDATE_CANDIDATE_DATA,
	SET_PROCESSED_MONTHS,
	GET_CANDIDATE_DOCUMENTS,
	GET_CANDIDATE_DOCUMENTS_SUCCESS,
	GET_CANDIDATE_DOCUMENTS_FAILED,
	SAVE_CANDIDATE_DOCUMENT,
	SAVE_CANDIDATE_DOCUMENT_SUCCESS,
	SAVE_CANDIDATE_DOCUMENT_FAILED,
} from './constants';


export const getContractorData = () => ({
    type: GET_CONTRACTOR,
});

export const getContractorDataSuccess = (contractor) => ({
    type: GET_CONTRACTOR_SUCCESS,
    payload: contractor
});

export const getContractorDataFailed = (error) => ({
    type: GET_CONTRACTOR_MONTH_DATA_FAILED,
    payload: error
});

export const getContractorMonthDataSuccess = (contractorMonthArray) => ({
    type: GET_CONTRACTOR_MONTH_DATA_SUCCESS,
    payload: contractorMonthArray,
});
export const getContractorMonthDataFailed = (error) => ({
    type: GET_CONTRACTOR_MONTH_DATA_FAILED,
    payload: error,
});

export const saveContractorMonthData = (contractorMonthData, isSilent) => ({
    type: SAVE_CONTRACTOR_MONTH_DATA,
    payload: {contractorMonthData, isSilent}
});
export const saveContractorMonthDataSuccess = (contractorMonthData, isSilent) => ({
    type: SAVE_CONTRACTOR_MONTH_DATA_SUCCESS,
    payload: {contractorMonthData, isSilent},
});
export const saveContractorMonthDataFailed = (error) => ({
    type: SAVE_CONTRACTOR_MONTH_DATA_FAILED,
    payload: error
});
export const setContractorLoader = (loaderState) => ({
    type: SET_CONTRACTOR_LOADER,
    payload: loaderState
});
export const updateContractorData = (contractor) => ({
	type: UPDATE_CONTRACTOR_DATA,
	payload: contractor,
});
export const updateCandidateData = (candidate) => ({
	type: UPDATE_CANDIDATE_DATA,
	payload: candidate,
});
export const setProcessedMonths = (processedMonths) => ({
	type: SET_PROCESSED_MONTHS,
	payload: processedMonths,
});
export const getCandidateDocuments = () => ({
	type: GET_CANDIDATE_DOCUMENTS,
});
export const getCandidateDocumentsSuccess = (candidateDocuments) => ({
	type: GET_CANDIDATE_DOCUMENTS_SUCCESS,
	payload: candidateDocuments
});
export const getCandidateDocumentsFailed = (error) => ({
	type: GET_CANDIDATE_DOCUMENTS_FAILED,
	payload: error
});
export const saveCandidateDocument = (newCandidateDocument) => ({
	type: SAVE_CANDIDATE_DOCUMENT,
	payload: newCandidateDocument,
});
export const saveCandidateDocumentSuccess = (candidateDocument) => ({
	type: SAVE_CANDIDATE_DOCUMENT_SUCCESS,
	payload: candidateDocument,
});
export const saveCandidateDocumentFailed = (error) => ({
	type: SAVE_CANDIDATE_DOCUMENT_FAILED,
	payload: error,
});