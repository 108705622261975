import { navPages } from './../../routes/pagePermissionMap';
import { ContractorMonthDataPayload, CandidateDocumentCreate, PagePermission } from './../../types';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import Bugsnag from '@bugsnag/js';

import { GET_CONTRACTOR, ROLE_ADMIN, ROLE_MANAGER, SAVE_CANDIDATE_DOCUMENT, SAVE_CONTRACTOR_MONTH_DATA } from './constants';
import {
    saveContractorMonthDataSuccess,
    saveContractorMonthDataFailed,
    getContractorDataFailed,
    setContractorLoader,
	saveCandidateDocumentSuccess,
	saveCandidateDocumentFailed,
} from './actions'
import axiosClient from '../../helpers/Axios';
import { setUserRole, addMenuItem, initialLoadAdminDb, setNimblUser, setPermissions } from '../actions';
import pagePermissionMap from '../../routes/pagePermissionMap';
import { handleLogout } from '../../helpers/authUtils';

function* saveContractorMonth(action: any){
	const payload: ContractorMonthDataPayload = action.payload;
	const cm = payload.contractorMonthData;
    const formData = new FormData();
    formData.append('contractor_comment', cm.contractor_comment);
    formData.append('number_of_hours', `${cm.number_of_hours}`);
    formData.append('created_date', new Date().toISOString() );
	formData.append('total_invoiced_amount', `${cm.total_invoiced_amount}`);
	if (cm.contractor_invoice_status_id){
		formData.append('contractor_invoice_status_id', cm.contractor_invoice_status_id);
	}
	if( cm.files && cm.files[0] ){
        formData.append('files', cm.files[0]);
	}
    try {
        if(cm.contractor_month_id){
            const contractorMonthId = cm.contractor_month_id;
            const response: AxiosResponse = yield call(axiosClient.put, `contractormonth/${contractorMonthId}/invoice`, formData, { headers:{
                'Content-Type':'multipart/form-data'
            }});
            if(response.data){
                yield put( saveContractorMonthDataSuccess(response.data, payload.isSilent) );
            }else{
				console.log('No data when saving cm');
                yield put( saveContractorMonthDataFailed('Error when saving new contractor month record, please refresh the page and try again') );
            }
        }else{
			console.log('Not cm id');
            yield put( saveContractorMonthDataFailed('Error when saving new contractor month record! Please try again') );
        }
    } catch (err) {
		const error = err as AxiosError;
        let errorToShow = 'Server error! Please try again.';
		console.log('Error saving cm', error, error.response, error.response?.data);
		Bugsnag.notify(error);
        if(error && error.response){
            switch(error.response.status){
                case 400:
                    errorToShow = 'Please complete all the fields and try again';
                    break;
                case 401: 
                case 403:
                    errorToShow = 'Session expired! Please re login';
                    break;
                default:
                    errorToShow = 'Server error! Please try again.';
            }
        }
        yield put( saveContractorMonthDataFailed(errorToShow) );
    }
}

function* getContractor(){
	let response:AxiosResponse | null = null;
	try {
		response = yield call( axiosClient.get, `/user` );
	} catch (err) {
		const error = err as AxiosError;
		console.log('Error getting user info', error, error.response, error.response?.data);
		Bugsnag.notify(error);
		const swalObj = {
			title: "Error",
			text: "",
			icon: "error" as SweetAlertIcon,
		}
		let errorMsg = `Server error! Please try again ${error}`;
		if (error?.response?.status && error?.response?.status >= 400 && error?.response?.status < 500) {
			swalObj.text = "Your session has ended. Please refresh the page.";
			swalObj.icon = "warning" as SweetAlertIcon;
			swalObj.title = "Session expired";
		} else {
			swalObj.text = `Error getting user information ${error}`;
			swalObj.icon = "error";
		}
		yield put( getContractorDataFailed(errorMsg) );
		Swal.fire(swalObj).then(handleLogout);
	}
	if(response){
		const pagePermissions: PagePermission[] = response?.data?.permissions.pages;
		const pagePermissionSet = new Set(pagePermissions.map( (p) => p.page_name));
		for(const pageName of Array.from(pagePermissionSet)){
			if(pagePermissionMap.get(pageName) && navPages.includes(pageName)){
				const route = pagePermissionMap.get(pageName)
				yield put(addMenuItem(route)); //ADDING MENU ITEMS TO ADMIN LEFT SIDEBAR
			}
		}
		yield put(setPermissions({
			pagePermissions: [...response?.data?.permissions.pages, {page_name: 'index', page_path: '/', page_id: 9999999 }],
			actionPermissions: response?.data?.permissions.actions
		}));
	}
	if( response?.data && (response.data.role === ROLE_ADMIN || response.data.role === ROLE_MANAGER) && response.data.user_id  ){
		yield put( setUserRole(response.data.role) );
		yield put( setNimblUser(response.data) ); //LAST AUTH CALL IS SET NIMBL_USER
		yield put( initialLoadAdminDb() );
		yield put( setContractorLoader(false) );	
	}else{
		yield put(setNimblUser(null));
	}
}
function* saveCandidateDocumentToDB(action: any){
	const payload: CandidateDocumentCreate = action.payload;
	const formData = new FormData();
	formData.append('candidate_document_type_id', `${payload.candidate_document_type_id}`);
	formData.append('files', payload.files[0]);
	formData.append('created_date', payload.created_date );
	try {
		const response:AxiosResponse = yield call(
			axiosClient.post,
			`candidate/${payload.candidate_id}/documents`,
			formData, {
				headers:{
					'Content-Type':'multipart/form-data'
				}
			}
		);
		if(response.data){
			yield put( saveCandidateDocumentSuccess(response.data));
		}
	} catch (err) {
		const error = err as AxiosError;
		console.log('error saving Candidate Doc', error, error.response, error.response?.data);
		Bugsnag.notify(error);
		yield put( saveCandidateDocumentFailed('Error when saving new document! Please try again') );
	}
}
export function* watchSaveContractorMonthData(){
    yield takeEvery(SAVE_CONTRACTOR_MONTH_DATA, saveContractorMonth);
}
export function* watchGetContractor(){
    yield takeEvery(GET_CONTRACTOR, getContractor);
}
export function* watchSaveCandidateDocuments(){
	yield takeEvery(SAVE_CANDIDATE_DOCUMENT, saveCandidateDocumentToDB);
}
function* contractorViewSaga(){
    yield all([ fork(watchSaveContractorMonthData), fork(watchGetContractor), fork(watchSaveCandidateDocuments) ]);
}
export default contractorViewSaga;