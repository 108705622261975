import Bugsnag from "@bugsnag/js";
import { AxiosError } from "axios";

import { AuditCreate, Recruiter } from "../types";
import { NIMBL_USER_STORAGE_KEY } from ".";
import axiosClient from "./Axios";

export const saveAudit = async (reqBody: AuditCreate | Omit<AuditCreate, "user_id"> ) => {
	let user_id = 0; 
	try {
		const str = localStorage.getItem(NIMBL_USER_STORAGE_KEY);
		if(!str){
			throw new Error('Error getting user');
		}
		const recruiter: Recruiter = JSON.parse(str);
		if(!recruiter?.user_id){
			console.log({recruiter})
			throw new Error(`Error getting user id from recruiter: `);
		}
		user_id = recruiter.user_id;
	} catch (err) {
		const error = err as Error;
		console.log('Error saving audit');
		Bugsnag.notify(error);
		return;
	}
	try {
		//@ts-ignore
		if(reqBody.user_id){
			//@ts-ignore
			user_id = reqBody.user_id;
		}
		await axiosClient.post(`/audit`, {
			...reqBody,
			user_id,
		});
	} catch (err) {
		const error = err as AxiosError;
		console.log('Error saving audit', error, error?.response, error.response?.data);
		Bugsnag.notify(error);
	}
}