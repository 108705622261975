/* AUTH */
export const INIT_AUTH = 'INIT_AUTH';
export const FINISH_AUTH = 'FINISH_AUTH';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN';
export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_NIMBL_USER = 'SET_NIMBL_USER';

export const AUTH_TYPE_LINKEDIN = 'LINKEDIN_AUTH';
export const AUTH_TYPE_GOOGLE = 'GOOGLE_AUTH';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';