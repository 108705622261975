import { Cookies } from 'react-cookie';
import { googleLogout } from '@react-oauth/google';

import { UserType } from './../types';

import { AUTH_TYPE_LINKEDIN } from '../redux/auth/constants';
import axiosClient from './Axios';

/**
 * Returns the logged in user
 */
const getLoggedInUser: () => null | UserType = () => {
    const cookies = new Cookies();
    const user = cookies.get('user');
    if(user && new Date().getTime() <= user.sessionExpirationTimestamp){
        if( !axiosClient.defaults.headers.common['Authorization'] ){
            axiosClient.defaults.headers.common['Authorization'] = `Bearer ${user.accessToken}`;
			if(user.type === AUTH_TYPE_LINKEDIN){
				axiosClient.defaults.headers.common['x-auth-type'] = AUTH_TYPE_LINKEDIN;
			}
        }
        return typeof user === 'object' ? user : JSON.parse(user);
    }
    return null;
};

const googleAuthConfiguration = {
    redirectUri: process.env.REACT_APP_GOOGLE_REDIRECT_URI_LOCAL,
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
}
const currentEnv = window._CURRENT_ENV_;
if( currentEnv === 'DEV_ENV' ){
	googleAuthConfiguration.redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI_STG
}else if(currentEnv === 'PROD_ENV' || currentEnv === 'STG_ENV'){
    googleAuthConfiguration.redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI_PROD
}

const handleLogout = () => {
	googleLogout();
	let cookies = new Cookies();
	cookies.remove('user', { path: '/' });
	let loginUrl = "/account/login";
	if(window.location.href.includes('nimbl.ai') && !window.location.href.includes('admin')){
		loginUrl = '/adminportal/account/login';
	}
	window.location.href = loginUrl;
}

export { getLoggedInUser, handleLogout, googleAuthConfiguration };

