import { RouteComponentProps } from 'react-router-dom';
import { ActionPermission, GoogleLoginResponseLocal, NimblUserType, PagePermission } from '../../types';
// @flow
import {
    LOGOUT_USER,
    GOOGLE_LOGIN,
    SET_USER_ROLE,
	INIT_AUTH,
	SET_NIMBL_USER,
	SET_PERMISSIONS,
} from './constants';

export const initAuth = () => ({
	type: INIT_AUTH,
});
export const googleLoginUser = (googleResponseObj: GoogleLoginResponseLocal) => ({
    type: GOOGLE_LOGIN,
    payload: googleResponseObj,
});

export const logoutUser = (history: RouteComponentProps['history']) => ({
    type: LOGOUT_USER,
    payload: { history },
});

export const setUserRole = (role: string) => ({
    type: SET_USER_ROLE,
    payload: role
})
export const setPermissions = (permissions: {pagePermissions: PagePermission[], actionPermissions: ActionPermission[]}) => ({
	type: SET_PERMISSIONS,
	payload: permissions,
});
export const setNimblUser = (nimblUser: NimblUserType | null) => ({
    type: SET_NIMBL_USER,
    payload: nimblUser
});