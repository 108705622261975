import { SagaProp, UserType } from './../../types';
// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, takeEvery } from 'redux-saga/effects';


import { LOGOUT_USER } from './constants';

//import axiosClient from '../../helpers/Axios';


/**
 * Sets the session
 * @param {*} user
 */
export const setSession = (user: UserType| null) => {
    let cookies = new Cookies();
    if (user) cookies.set('user', JSON.stringify(user), { path: '/' });
    else cookies.remove('user', { path: '/' });
};

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }: SagaProp<any>) { //Use SagaProp<RouteComponentProps['history']>?
    try {
        setSession(null);
        yield call(() => {
            history.push('/account/login');
        });
    } catch (error) {}
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

function* authSaga() {
    yield all([
		fork(watchLogoutUser), 
	]);
}

export default authSaga;
