import { RevolutAuthWithExp } from './../types';
import axiosClient from './Axios';
const REVOLUT_AUTH = 'REVOLUT_AUTH';

export const setRevolutSession = async (revolutAuthData: RevolutAuthWithExp | null) => {
	if (revolutAuthData) {
		localStorage.setItem(REVOLUT_AUTH, JSON.stringify(revolutAuthData));
	} else {
		localStorage.removeItem(REVOLUT_AUTH);
	}
};

export const getRevolutSession = () => {
	const authDataStr = localStorage.getItem(REVOLUT_AUTH);
	try {
		if(authDataStr){
			const data: RevolutAuthWithExp = JSON.parse(authDataStr);
			if(data.expirationDatetime > Date.now()){
				axiosClient.defaults.headers.common['x-revoluth-auth'] = `Bearer ${data.code}`;
				return data;
			}
		}
	} catch (error) {
		console.warn('Error getting revolut auth data');
	}
	return null;
};