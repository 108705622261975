
import { AnyAction } from 'redux';
import { ContractorMonthState, RevolutAuth, StateContractor, RevolutAuthWithExp, Country, CandidateWithContractor } from '../../types';
import {
    GET_ALL_CONTRACTORS, GET_ALL_CONTRACTORS_ERROR, GET_ALL_CONTRACTORS_SUCCESS, 
    GET_CLIENTS, GET_CLIENTS_ERROR, GET_CLIENTS_SUCCESS,
    GET_POSSIBLE_INVOICE_STATUSES, GET_POSSIBLE_INVOICE_STATUSES_SUCCESS, GET_POSSIBLE_INVOICE_STATUSES_ERROR, 
    UPDATE_CONTRACTOR_MONTH_RECORD,
    INIT_MONTH_END_SUCCESS, INIT_MONTH_END_FAILED, INIT_MONTH_END, 
    GET_EXCHANGE_RATES, GET_EXCHANGE_RATES_SUCCESS, GET_EXCHANGE_RATES_FAILED, 
    UPDATE_EXCHAGE_RATE, UPDATE_EXCHAGE_RATE_SUCCESS, UPDATE_EXCHAGE_RATE_FAILED, 
    INITIAL_LOAD_ADMIN_DB, INITIAL_LOAD_ADMIN_DB_SUCCESS, INITIAL_LOAD_ADMIN_DB_FAILED, GET_CONTRACTOR_MONTH_RECORDS, 
	GET_CONTRACTOR_MONTH_RECORDS_SUCCESS, GET_CONTRACTOR_MONTH_RECORDS_FAILED, ADD_CLIENT, UPDATE_CLIENT, 
	ADD_CLIENT_CURRENCY, DELETE_CLIENT_CURRENCY, ADD_CONTRACTOR_DOCUMENT,
	SET_REVOLUT_AUTH_DATA,
	SET_SELECTED_CANDIDATE,
	EDIT_SELECTED_CANDIDATE,
	EDIT_SELECTED_CANDIDATE_SUCCESS,
	EDIT_SELECTED_CANDIDATE_FAILED
} from './constants';

import { addNewCMs, getEurToUsdRate, sortContractorMonths, sortContractors, sortRates, getProcessedMonths, mapContractorMonthFn } from './utils';
import { setRevolutSession, getRevolutSession } from '../../helpers/revolut';
import axiosClient from '../../helpers/Axios';

type State = {
    allContractors: StateContractor[],
    allContractorMonths: ContractorMonthState[],
    error: null | any,
    clients: any[],
    isLoading: boolean,
    possibleInvoiceStatuses: any[],
	clientsAreLoading: boolean,
    ratesAreLoading: boolean,
    cmGridIsLoading: boolean, //Contractor month grid
    exchangeRates: any[],
    eurUsdRate: null | any,
    processedMonths: any[],
    isInitialLoadFinished: boolean,
    currentUsdEurRate: number,
	monthEndFinished: boolean,
	countries: Country[],
	revolutAuth: RevolutAuthWithExp | null,
	selectedCandidate: CandidateWithContractor | null,
	candidateEditIsLoading: boolean,
};
const INIT_STATE: State = {
    allContractors: [],
    allContractorMonths: [],
    error: null,
    clients: [],
    isLoading: false,
    possibleInvoiceStatuses: [],
	clientsAreLoading: false,
    ratesAreLoading: false,
    cmGridIsLoading: false, //Contractor month grid
    exchangeRates: [],
    eurUsdRate: null,
    processedMonths: [],
    isInitialLoadFinished: false,
    currentUsdEurRate: 0.95,
	monthEndFinished: false,
	countries: [],
	revolutAuth: getRevolutSession(),
	selectedCandidate: null,
	candidateEditIsLoading: false,
};

const AdminDashboard  = ( state = INIT_STATE, action:AnyAction ) => {
    switch( action.type ){
        case INITIAL_LOAD_ADMIN_DB: 
            return {
                ...state,
                isLoading: true,
                ratesAreLoading: true,
                cmGridIsLoading: true,
				monthEndFinished: false,
				clientsAreLoading: true,
            } satisfies State;
        case INITIAL_LOAD_ADMIN_DB_SUCCESS: 
            const sortedContractors = sortContractors(action.payload.contractors);
            const sortedCms = sortContractorMonths(action.payload.contractorMonths);
            const exchangeRates = sortRates(action.payload.rates);
            const eurToUsdRate = getEurToUsdRate(exchangeRates);
            const processedMonths = getProcessedMonths(action.payload.processedMonths);
            return {
                ...state,
                isLoading: false,
                ratesAreLoading: false,
                cmGridIsLoading: false,
				clientsAreLoading: false,
                allContractors: sortedContractors,
                clients: action.payload.clients,
                possibleInvoiceStatuses: action.payload.invoiceStatuses,
                exchangeRates,
                eurUsdRate: eurToUsdRate,
                allContractorMonths: sortedCms,
                processedMonths,
                isInitialLoadFinished: true,
                currentUsdEurRate: action.payload.currentUsdEurRate,
				countries: action.payload.countries,
            } satisfies State;
        case INITIAL_LOAD_ADMIN_DB_FAILED:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
                ratesAreLoading: false,
                cmGridIsLoading: false,
                isInitialLoadFinished: true,
				clientsAreLoading: false,
            } satisfies State;
        case GET_ALL_CONTRACTORS:
            return {
                ...state,
                allContractors: [],
                error: null,
                isLoading: true,
            } satisfies State;
        case GET_ALL_CONTRACTORS_SUCCESS:
            const contractorsSorted = sortContractors(action.payload);
            return {
                ...state,
                allContractors: contractorsSorted,
                error: null,
                isLoading: false,
            } satisfies State;
        case GET_ALL_CONTRACTORS_ERROR: 
            return{
                ...state,
                allContractors: [],
                error: action.payload,
                isLoading: false,
            } satisfies State;
        case GET_CLIENTS_ERROR: 
            return {
                ...state,
                clients: [],
				clientsAreLoading: false,
                error: action.payload,
                isLoading: false,
            } satisfies State;
        case GET_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: action.payload,
                error: null,
                isLoading: false,
				clientsAreLoading: false,
            } satisfies State;
        case GET_CLIENTS:
            return {
                ...state,
                isLoading: true,
				clientsAreLoading: true,
                clients: [],
                error: null
            } satisfies State;
        case GET_POSSIBLE_INVOICE_STATUSES:
            return{
                ...state,
                isLoading: true,
                possibleInvoiceStatuses: [],
                error: null,
            } satisfies State;
        case GET_POSSIBLE_INVOICE_STATUSES_SUCCESS:
            return{
                ...state,
                isLoading: false,
                possibleInvoiceStatuses: action.payload,
                error: null,
            } satisfies State;
        case GET_POSSIBLE_INVOICE_STATUSES_ERROR:
            return{
                ...state,
                isLoading: false,
                possibleInvoiceStatuses: [],
                error: action.payload,
            } satisfies State;
        case UPDATE_CONTRACTOR_MONTH_RECORD:
            const cmRecord = action.payload;
            let allCms = state.allContractorMonths.map( cm => cm.contractor_month_id === cmRecord.contractor_month_id ? {
                ...cm,
                ...cmRecord
            } : cm);
			allCms = allCms.map(mapContractorMonthFn);
            return {
                ...state,
                allContractorMonths: allCms,
            } satisfies State;
        case INIT_MONTH_END:
            return {
                ...state,
                // isLoading: true,
            } satisfies State;
        case INIT_MONTH_END_FAILED:
            return {
                ...state,
                error: action.payload,
                // isLoading: false,
            } satisfies State;
        case INIT_MONTH_END_SUCCESS:
            const contractorsUpdated = addNewCMs( state.allContractors, action.payload )
            return {
                ...state,
                // isLoading: false,
				monthEndFinished: true,
                allContractors: contractorsUpdated
            } satisfies State;
        case GET_EXCHANGE_RATES:
            return {
                ...state,
                exchangeRates: [],
                eurUsdRate: null,
                error: null,
                ratesAreLoading:  true,
            } satisfies State;
        case GET_EXCHANGE_RATES_SUCCESS:
            const exchangeRatesSorted = sortRates(action.payload);
            const eurUsdRate = getEurToUsdRate(action.payload);
            return {
                ...state,
                exchangeRates: exchangeRatesSorted,
                error: null,
                ratesAreLoading:  false,
                eurUsdRate
            } satisfies State;
        case GET_EXCHANGE_RATES_FAILED:
            return {
                ...state,
                exchangeRates: [],
                eurUsdRate: null,
                error: action.payload,
                ratesAreLoading:  false,
            } satisfies State;
        case UPDATE_EXCHAGE_RATE:
            return {
                ...state,
                error: null,
                ratesAreLoading: true,
            } satisfies State;
        case UPDATE_EXCHAGE_RATE_SUCCESS:
            const newRate = action.payload;
            const updatedExchangeRates = state.exchangeRates.map( r => r.rate_id === newRate.rate_id ? newRate : r )
            const newEurUsd = ( newRate.from_currency === 'EUR' && newRate.to_currency === 'USD') ? newRate : state.eurUsdRate;
            return {
                ...state,
                error: null,
                ratesAreLoading: false,
                exchangeRates: updatedExchangeRates,
                eurUsdRate: newEurUsd,
            } satisfies State;
        case UPDATE_EXCHAGE_RATE_FAILED:
            return {
                ...state,
                error: action.payload,
                ratesAreLoading: false
            } satisfies State;
        case GET_CONTRACTOR_MONTH_RECORDS:
            return {
                ...state,
                error: null,
                cmGridIsLoading: true,
            } satisfies State;
        case GET_CONTRACTOR_MONTH_RECORDS_SUCCESS: 
            const sortedCmRecords = sortContractorMonths(action.payload);
            return {
                ...state,
                error: null,
                cmGridIsLoading: false,
                allContractorMonths: sortedCmRecords
            } satisfies State;
        case GET_CONTRACTOR_MONTH_RECORDS_FAILED:
            return {
                ...state,
                error: action.payload,
                cmGridIsLoading: false,
                allContractorMonths: [],
            } satisfies State;
		case ADD_CLIENT: 
			return {
				...state,
				clients: [...state.clients, action.payload],
			} satisfies State;
		case UPDATE_CLIENT: 
			const updatedClient = action.payload
			const newClients = state.clients.map(c => c.client_id === updatedClient.client_id ? updatedClient : c);
			return {
				...state,
				clients: newClients,
			} satisfies State;
		case ADD_CLIENT_CURRENCY: {
			const clientCurrency = action.payload;
			const clients = state.clients.map(c => {
				const existingClientCurrency = Array.isArray(c?.client_currency) ? c.client_currency : [];
				return c.client_id === clientCurrency.client_id ? 
				{
					...c,
					client_currency: [ ...existingClientCurrency, clientCurrency]
				}: c
			})
			return {
				...state,
				clients
			} satisfies State;
		} case DELETE_CLIENT_CURRENCY: {
			const clientCurrency = action.payload;
			const clients = state.clients.map(c => c.client_id === clientCurrency.client_id ? 
				{
					...c,
					client_currency: c.client_currency.filter((cc:any) => cc.currency !== clientCurrency.currency)
				}: c
			)
			return {
				...state,
				clients
			} satisfies State;
		}
		case ADD_CONTRACTOR_DOCUMENT: {
			const doc = action.payload;
			const candidate_id = doc.candidate_id;
			const con = state.allContractors.find(con => con.candidate_id === candidate_id);
			let allContractors = state.allContractors
			if(con){
				con.candidate_document = Array.isArray(con?.candidate_document) ? con.candidate_document : [];
				con.candidate_document.push(doc);
				allContractors = state.allContractors.map( c => c.candidate_id === candidate_id ? con: c );
			}
			return {
				...state,
				allContractors,
			} satisfies State;
		}
		case SET_REVOLUT_AUTH_DATA: {
			const data: RevolutAuth | null = action.payload;
			if(!data) {
				setRevolutSession(null);
				return {
					...state,
					revolutAuth: null,
				} satisfies State;
			}
			axiosClient.defaults.headers.common['x-revoluth-auth'] = `Bearer ${data.code}`;
			const expirationDatetime = new Date().getTime() + data.expires_in * 1000; //expires in is in seconds
			const revolutAuth = {
				...state.revolutAuth,
				...data,
				expirationDatetime,
			};
			setRevolutSession(revolutAuth);
			return {
				...state,
				revolutAuth,
			} satisfies State;
		}
		case SET_SELECTED_CANDIDATE: {
			const selectedCandidate = action.payload;
			return {
				...state,
				selectedCandidate,
			} satisfies State;
		}
		case EDIT_SELECTED_CANDIDATE: {
			return {
				...state,
				candidateEditIsLoading: true,
			} satisfies State;
		}
		case EDIT_SELECTED_CANDIDATE_SUCCESS: {
			return {
				...state,
				candidateEditIsLoading: false,
				selectedCandidate: {
					...state.selectedCandidate,
					...action.payload,
				},
			} satisfies State;
		}
		case EDIT_SELECTED_CANDIDATE_FAILED: {
			return {
				...state,
				candidateEditIsLoading: false,
			} satisfies State;
		}
        default:
            return {...state} satisfies State;
    }
}
export default AdminDashboard;