import React, { useEffect, useState } from 'react';
import { REVOLUT_OAUTH2_STATE } from '../../../helpers';

//Credits: https://github.com/nvh95/react-linkedin-login-oauth2/blob/master/src/LinkedInCallback.tsx

const parse = (search) => {
	const query = search.substring(1);
	const vars = query.split('&');
	const parsed = {};
	for (let i = 0; i < vars.length; i++) {
		const pair = vars[i].split('=');
		if (pair.length > 1) {
			parsed[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
		}
	}
	return parsed;
}

const RevolutCallback = () => {
	const [errorMessage, setErrorMessage] = useState('');
	useEffect(() => {
		const params = parse(window.location.search);
		if (params.state !== REVOLUT_OAUTH2_STATE) {
			setErrorMessage('State does not match');
		} else 
		if (params.error) {
			const errorMessage =
			params.error_description || 'Login failed. Please try again.';
			window.opener &&
			window.opener.postMessage( // POST MESSAGE TO PARENT WINDOW
				{
					error: params.error,
					state: params.state,
					errorMessage,
				},
				window.location.origin,
			);
			// Close tab if user cancelled login
			if (params.error === 'user_cancelled_login') {
				window.close();
			}
		}
		else if (params.code) {
			window.opener &&
			window.opener.postMessage( // POST MESSAGE TO PARENT WINDOW
				{
					...params,
				},
				window.location.origin,
			);
		}
	}, []);
	return <div>{errorMessage}</div>;
};
export default RevolutCallback;