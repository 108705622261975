import React from "react";
import { connect } from "react-redux";

import { RootState } from "../redux/reducers";
import { ActionPermission } from "../types";

type Props = {
	tag: string;
	children: React.ReactNode;
	actionPermissions: ActionPermission[];
};

const PrivateCmp = ({ children, tag, actionPermissions }: Props) => {
	const hasPermission = actionPermissions.some((permission) => permission.action_tag === tag);
	return hasPermission ? <React.Fragment>{children}</React.Fragment> : <></>;
};
const mapStateToProps = (state: RootState) => ({
	pagePermissions: state.Auth.pagePermissions,
	actionPermissions: state.Auth.actionPermissions,
});
export default connect(mapStateToProps, {})(PrivateCmp);