import React from 'react';
import { CheckCircle, Globe, Home, Minimize2, Paperclip, XCircle } from 'react-feather';
import { Input } from 'reactstrap';
import PaymentCell from '../contractorApp/components/AdminView/PaymentCell';
import RevolutLogo from '../assets/contractorApp/revolut.png';
import Bugsnag from '@bugsnag/js';
import { ColumnDescription, SizePerPageRendererOptions } from 'react-bootstrap-table-next';
import { Client, ClientInvoice, Contractor, ContractorMonth, Country, Rate } from '../types';
import axiosClient from './Axios';
import { AxiosError } from 'axios';
// CONSTANTS
export const ADMIN_EMAIL = 'david@nimbl.ai';
export const LOCAL_ENV = 'LOCAL_ENV';
export const DEV_ENV = 'DEV_ENV';
export const STG_ENV = 'STG_ENV';
export const PROD_ENV = 'PROD_ENV';
export const INVOICE_STATUS_PENDING = 1;
export const INVOICE_STATUS_UPLOADED = 2;
export const INVOICE_STATUS_PREPARED = 3;
export const INVOICE_STATUS_PAID = 4;

export const CLIENT_INVOICE_STATUS_NOT_PAID = 'Not paid';
export const CLIENT_INVOICE_STATUS_PAID = 'Paid';
export const CLIENT_INVOICE_STATUS_SENT = 'Sent';
export const CLIENT_INVOICE_STATUS_PAYMENT_SENT = 'Payment sent';

export const CLIENT_INVOICE_ACC_STATUS_PROCESSED = 'Processed';
export const CLIENT_INVOICE_ACC_STATUS_NOT_PROCESSED = 'Not processed';

export const PAYMENT_TYPE_MONTHLY = 'Monthly';
export const PAYMENT_TYPE_HOURLY = 'Hourly';
export const DOCUMENT_TYPE_RESUME = 2;
export const DOCUMENT_TYPE_SOW = 6;

export const USER_TYPE_ID_CONTACT = 3;

export const RM_INVOICE_NOT_UPLOADED = 'Not uploaded';
export const RM_INVOICE_NOT_PAID = 'Not paid';
export const RM_INVOICE_PREPARED = 'Prepared';
export const RM_INVOICE_PAID = 'Paid';

export const contractorInvoiceStatus = ['', 'Not uploaded', 'Not paid', 'Payment sent','Paid' ];
export const monthNamesArray = ['January', 'February','March','April', 'May', 'June','July','August','September','October','November','December'];
export const LINKEDIN_OAUTH2_STATE = 'linkedin_oauth2_state';
export const REVOLUT_OAUTH2_STATE = 'revolut_oauth2_state';

export const NIMBL_USER_STORAGE_KEY = 'nimbl_user';

export const EXPENSE_SOURCE_WEB = 'Web App';
export const EXPENSE_SOURCE_APP = 'Phone App';
export const EXPENSE_SOURCE_FILE = 'Expense File';
export const EXPENSE_SOURCES = [EXPENSE_SOURCE_WEB, EXPENSE_SOURCE_APP, EXPENSE_SOURCE_FILE];

export const PAPERWORK_STATUS_CREATED = "Created";
export const PAPERWORK_STATUS_SENT = "Sent to sign";
export const PAPERWORK_STATUS_SIGNED = "Signed";

export const REFERRAL_STATUS_NEW = "New";
export const REFERRAL_STATUS_REVIEW = "Under Review";
export const REFERRAL_STATUS_ACCEPTED = "Accepted";
export const REFERRAL_STATUS_HOLD = "On hold";

export const CLIENT_PORTAL = 'Client Portal';

export const PAY_METHOD_OPTIONS = [
	{ value: 'Net Cash International', label: 'Net Cash 🌎' }, 
	{ value: 'Net Cash Intermediary', label: 'Net Cash 🏦' }, 
	{ value: 'Net Cash Local', label: 'Net Cash 🇪🇺' }, 
	{ value: 'Wise', label: 'Wise' },
	{ value: 'Revolut', label: 'Revolut' },
];
const symbolMap = {
    'EUR': '€', 'USD':'$'
}

export const NOTIFICATION_TYPE = {
	SEND_INVOICE_TO_CLIENT: 2,
	REFERRAL_CREATED: 4,
	CLIENT_SIGNED_DOCUMENT: 10,
};
export const PAGES = {
	CLIENT_PAGE: '/admin/client',
	CLIENT_INVOICES_PAGE: '/admin/client/invoices',
	REFERRALS_PAGE: '/referrals',
	REFERRALS_TABLE_PAGE: '/referrals/view',
	NOTIFICATION_TYPES: '/notificationtypes',
};
// HELPER FUNCTIONS
export const currencyFormatter = (number: number, currency: 'EUR' | 'USD') => {   
    let symbol = symbolMap[currency] || '';
    if( typeof number === 'number' ){
        return `${symbol}${number.toLocaleString(undefined, { maximumFractionDigits:2, minimumFractionDigits:2 })}`;
    }else{
        return `${number}`;
    }
};
export const currencyFormatterLetters = (number: number, currency: string) => {   
    if( typeof number === 'number' ){
        return `${currency} ${number.toLocaleString(undefined, { maximumFractionDigits:2, minimumFractionDigits:2, useGrouping: true, })}`;
    }else{
        return `${number}`;
    }
};

export const footerHoursSumFunction = (columnData: number[], column: ColumnDescription, columnIndex: number) => {
	const totalHours = columnData.reduce((acc, item) => acc + item, 0) || 0;
	return totalHours.toLocaleString(undefined, { maximumFractionDigits:0, minimumFractionDigits:0 });
};
export const enforceDateFormat = (supposedDate: string | null) => 
	(supposedDate && supposedDate.length !== 10 ? supposedDate.substring(0,10) : supposedDate) || '';

export const footerSumFunction = (columnData: number[], column: ColumnDescription, columnIndex: number) => 
	`${columnData.reduce((acc, item) => acc + item, 0)}`;

type FooterFormaterType = {
	monthly_margin: number;
	total_invoiced_amount: number | null;
	total_invoiced_converted?: number;
	total_charge_amount: number | null;
	payment_currency: string;
	invoice_currency: string;
}
export const footerFormatter = (
	allContractors: FooterFormaterType[],
	attr: 'monthly_margin' | 'total_invoiced_amount' | 'total_invoiced_converted' | 'total_charge_amount',
	rate: 'payment_currency' | 'invoice_currency',
	usdEurRate = 0.95
) => {
    const totalUsd = allContractors.filter(c => c[rate] === 'USD').reduce(
        (sum, c) => sum + (c[attr] || 0), 
        0
    );
    const totalEur = allContractors.filter(c => c[rate] === 'EUR').reduce(
        (sum, c) => sum + (c[attr] || 0), 
        0
    );
    const total = totalEur + totalUsd * usdEurRate;
    const totalUsdFormatted = currencyFormatter(totalUsd, 'USD');
    const totalEurFormatted = currencyFormatter(totalEur, 'EUR');
    const totalFormatted = currencyFormatter(total, 'EUR');
    return (
        <>
            {totalUsdFormatted} <br/>
            {totalEurFormatted} <hr/>
            {totalFormatted}
        </>
    );
}

export const clientFooterFormatter = (
	invoices: ClientInvoice[],
	usdEurRate = 0.95
) => {
    const totalUsd = invoices.filter(({ currency, invoice_total }) => currency === 'USD').reduce(
        (sum, c) => sum + (c.invoice_total || 0), 
        0
    );
    const totalEur = invoices.filter(({ currency, invoice_total }) => currency === 'EUR').reduce(
        (sum, c) => sum + (c.invoice_total || 0), 
        0
    );
    const total = totalEur + totalUsd * usdEurRate;
    const totalUsdFormatted = currencyFormatter(totalUsd, 'USD');
    const totalEurFormatted = currencyFormatter(totalEur, 'EUR');
    const totalFormatted = currencyFormatter(total, 'EUR');
    return (
        <>
            {totalUsdFormatted} <br/>
            {totalEurFormatted} <hr/>
            {totalFormatted}
        </>
    );
}
export const format2digitStr = (n: number) => {
    try {
        return n.toLocaleString("en-US", { minimumIntegerDigits: 2 });   
    } catch (error) {
        console.error(error);
    }
    if(typeof n === 'number'){
        return n >= 10 ? `${n}`: `0${n}`;
    }
    return n;
}
export const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }: SizePerPageRendererOptions) => (
    <React.Fragment>
        <label className="d-inline mr-1">Show</label>
        <Input type="select" name="select" id="no-entries" className="custom-select custom-select-sm d-inline col-1"
            defaultValue={currSizePerPage}
            onChange={(e) => onSizePerPageChange(Number(e.target.value) || 0)}>
            {options.map((option, idx) => {
                return <option key={idx}>{option.text}</option>
            })}
        </Input>
        <label className="d-inline ml-1">entries</label>
    </React.Fragment>
);
export const priceFormatter = (ammountProperty: string, currencyProperty: string) => 
(cell: any, row: any) => {
    if( row[ammountProperty] ){
        return currencyFormatter(row[ammountProperty], row[currencyProperty])
    }else{
        return '';
    }
}
export const dateStrFormatterToMonth = (cell: string | null) => {
	if(cell){
		const d = convertStrToDate(cell);
		if(d){
			return d.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
		}
	}
	return cell;
}
export const dateStrFormatter = (cell: string | null) => {
	if(cell){
		return convertStrToDate(cell)?.toLocaleDateString() || "";
	}else return cell;
}
export const formatReconciledWithIcons = (cell: any | null) => {
	return cell ? <CheckCircle size={16} /> : <XCircle size={16}/>;
}
export const paymentMethodFormatter = (cell: any, row: ContractorMonth) => {
	let text = row.payment_method || "";
	let icon = null;
	if (text.includes('Net Cash')) {
		if (text.includes('Intermediary')) {
			icon = <Minimize2 width={15}/>;
		} else if (text.includes('International')) {
			icon = <Globe width={15}/>;
		} else if (text.includes('Local')) {
			icon = <Home width={15}/>;
		}
		text = 'Net Cash';
	}
	const obj = row.contractor || row;
	const idStr = `popoverContactor${row.contractor ? row.contractor_month_id : row.contractor_id}`
	return <PaymentCell text={text} icon={icon} contractor={obj}
		idStr={idStr}
	/>
}

export const PopoverLine = ({ title, value }: {title: string, value: string}) => ( value ?
	<div className='d-flex justify-content-between' onClick={() => navigator.clipboard.writeText(value)}>
		<p style={{fontSize: "0.85rem"}}>
			<b>{title}:</b> {value}
		</p>
		<span className='d-flex justify-content-end' style={{width: '17px'}}>
			<Paperclip 
				size={16}
				className="copyToClipboardIcon" 
			/>
		</span>
	</div> : null
);

export const convertCurrency = (ammount: number, fromCurr: string, toCurr: string, availableRates: Rate[]) => {
    let rate = availableRates.find(({from_currency, to_currency})=>from_currency === fromCurr && to_currency === toCurr)?.rate_value;
    if( !rate ){
        // If the conversion is not present, maybe the inverse conversion is
        const rateValue = availableRates.find(({from_currency, to_currency})=>from_currency === toCurr && to_currency === fromCurr)?.rate_value || 1;
        rate = 1 / rateValue;
    }
    return rate * ammount;
}
/**
 * It takes a string in the format of "YYYY-MM-DD" and returns a JavaScript Date object
 * @param strDate - The string date to convert to a Date object.
 * @returns A date object.
 */
export const convertStrToDate = (strDate: string) => {
	if(typeof strDate !== 'string' || strDate.length < 10){
		console.log("Error converting str to date!");
		Bugsnag.notify(new Error("Error converting str to date!"));
		// throw new Error("Error converting str to date!");
		return null;
	}
	//YYYY-MM-DD date
	const year = Number(strDate.substring(0, 4));
	const month = Number(strDate.substring(5, 7));
	const day = Number(strDate.substring(8, 10));
	return new Date(year, month - 1, day);
}
export const areFloatsEqual = (float1: number, float2: number) => (
	parseFloat(`${float1}`).toFixed(2) === parseFloat(`${float2}`).toFixed(2)
)
export const PayMethodOptions = () => {
	return PAY_METHOD_OPTIONS.map( 
		({ value, label }, i) => <option key={i} value={value}>{label}</option>
	);
};
export const getStatusBadge = (cm: ContractorMonth, classes = '') => {
	const statusArray = [
		{class: 'danger', title:'Not uploaded'},
		{class: 'danger', title:'Not uploaded'}, 
        {class: 'warning', title:'Not paid'},
		{class: 'warning', title:'Payment sent'},  
        {class: 'success', title:'Paid'}
    ];
	return(
		<span className={`badge badge-soft-${statusArray[cm.contractor_invoice_status_id]?.class} py-1 ${classes}`}>
			{statusArray[cm.contractor_invoice_status_id]?.title}
		</span>
	);
}
export const mappingClientToSelectValues = (c: Client) => ({ ...c, value: c.client_id, label: c.client_name });
export const mappingCountryToSelectValues = (c: Country) => ({ ...c, value: c.country_id, label: c.country_name });


export function getBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result as string;
            const encodedString = base64String.split(',')[1];
            resolve(encodedString);
        };

        reader.onerror = () => {
            reject(new Error('Failed to read file.'));
        };

        reader.readAsDataURL(file);
    });
}
export const LogInWithRevolutDropdown = () => (
	<>
		<span className='revolutSpan'>
			<img src={RevolutLogo} className="revolutLogo" alt="revolut logo" /> 
		</span> Log in
	</>
);

export const INITIAL_CONTRACTOR = {
    charge_rate: 0,
    company_address: '',
    company_name: "",
    company_tax_id: "",
    company_type: "",
    payment_currency: "USD",
    invoice_currency: "USD",
    payment_method: "Revolut",
    date_of_birth: "",
	current_contract_end_date: "",
    hourly_rate: 0,
    start_date: "",
    client_id: 0,
	job_title: "",
	main_task: 'Time and Material resource supply for consultancy services on the Salesforce platform and solution.',
	intermediary_bank_account_number: '',
	intermediary_bank_swift: '',
	payment_type: PAYMENT_TYPE_HOURLY,
	revolut_counterparty_id: '',
	document_url: '',
	first_name: '',
	last_name: '',
	middle_name: '',
	gender: '',
};
export const setContractor = (receivedContractor: Contractor) => {
    const con = { ...INITIAL_CONTRACTOR };
    for( let attr in con ){
		// @ts-ignore
        if(receivedContractor[attr]){
			// @ts-ignore
            con[attr] = receivedContractor[attr];
        }
    }
    return con;
}

export const openWindowWithClientDoc = async(id: number| null) => {
	if(!id) {
		return
	}
	try {
		const documents = await axiosClient.get(`/clientdocuments?client_document_id=${id}`);
		window.open(documents.data[0]?.document_address, '_blank');
	} catch (err) {
		const error = err as AxiosError;
		console.log('Error ', error, error.response, error.response?.data);
		Bugsnag.notify(error);
	}
};
export const openWindowWithCandidateDoc = async(id: number| null) => {
	if(!id) {
		return
	}
	try {
		const documents = await axiosClient.get(`/candidatedocuments?candidate_document_id=${id}`);
		if(documents.data[0]?.document_address){
			window.open(documents.data[0]?.document_address, '_blank');
		}
	} catch (err) {
		const error = err as AxiosError;
		console.log('Error open candidate document', error, error.response, error.response?.data);
		Bugsnag.notify(error);
	}
};

export const getTodayTimestamp = () => {
	const today = new Date();
	const _month = today.getMonth() + 1;
	const month = _month >= 10 ? _month : `0${_month}`;
	const _minutes = today.getMinutes();
	const minutes = _minutes >= 10 ? _minutes : `0${_minutes}`;
	const _hours = today.getHours();
	const hours = _hours >= 10 ? _hours : `0${_hours}`;
	const _date = today.getDate();
	const date = _date >= 10 ? _date : `0${_date}`;
	const str = `${today.getFullYear()}-${month}-${date}T${hours}:${minutes}`;
	// 'YYYY-MM-DDThh:mm'
	return str;
}

export const EMPTY_REFERRAL = {
	date: new Date().toISOString().substring(0, 10),
	first_name: '',
	last_name: '',
	// user_id: user?.user_id || 0,
	country_id: null,
	email: '',
	is_active: true,
	job_title: '',
	linkedin_url: '',
	observations: '',
	phone: '',
	referral_candidate_id: null,
	admin_observations: '',
	status: REFERRAL_STATUS_NEW,
}