import { AnyAction } from 'redux';
import { NimblUserType, PagePermission, UserType, ActionPermission, Recruiter } from './../../types';
import {
    LOGOUT_USER,
    GOOGLE_LOGIN,
    SET_USER_ROLE,
    SET_NIMBL_USER,
	AUTH_TYPE_GOOGLE,
	INIT_AUTH,
	SET_PERMISSIONS
} from './constants';
import { getLoggedInUser } from '../../helpers/authUtils';
import axiosClient from '../../helpers/Axios';
import { setSession } from './saga';
import Bugsnag from '@bugsnag/js';
import { NIMBL_USER_STORAGE_KEY } from '../../helpers';
// import { saveAudit } from '../../helpers/audit';

export type AuthState = {
	user: UserType | null;
	nimblUser: null | NimblUserType & Recruiter;
	loading: boolean;
	isAuthCompleted: boolean;
	role: null | 'LINKEDIN_AUTH' | 'GOOGLE_AUTH';
	pagePermissions: PagePermission[];
	actionPermissions: ActionPermission[];
}

// @flow
const INIT_STATE: AuthState = {
    user: getLoggedInUser(),
    nimblUser: null,
    loading: false,
	isAuthCompleted: false,
	role: null,
	pagePermissions: [],
	actionPermissions: [],
};

const Auth = (state = INIT_STATE, action: AnyAction) => {
    switch (action.type) {
		case INIT_AUTH: 
			return {...state, loading: true};
        case LOGOUT_USER:
            return { ...state, user: null };
		
        case GOOGLE_LOGIN:
            axiosClient.defaults.headers.common['Authorization'] = `Bearer ${action.payload.accessToken}`;
            const user: UserType = {
                id: action.payload.profileObj.googleId,
                username: action.payload.profileObj.email,
                email:  action.payload.profileObj.email,
                firstName: action.payload.profileObj.givenName,
                lastName: action.payload.profileObj.familyName,
                fullName: action.payload.profileObj.name,
                accessToken: action.payload.accessToken,
				type: AUTH_TYPE_GOOGLE,
				sessionExpirationTimestamp: new Date().getTime() +  action.payload.tokenObj.expires_in * 1000
            };
            setSession(user);
			// TODO: Save audit after login
			// saveAudit({
			// 	action_id: 96,
			// 	before_data: null,
			// 	after_data: {...user},
			// 	error: '',
			// 	observations: `${user.fullName} with email ${user.email} logged in with Google.`,
			// 	datetime_start: new Date().toISOString(),
			// 	has_error: false,
			// 	user_id: 
			// });
            return {
                ...state, 
                user,
            };
        case SET_NIMBL_USER:{
			const recruiter: Recruiter | null = action.payload;
			if(recruiter){
				Bugsnag.setUser(`${recruiter.user_id}`, recruiter.recruiter_email, `${recruiter.recruiter_first_name} ${recruiter.recruiter_last_name}`);
			}
			localStorage.setItem(NIMBL_USER_STORAGE_KEY, JSON.stringify(recruiter));
            return {
                ...state,
                nimblUser: action.payload,
				loading: false, //SET NIMBL USER IS LAST CALL
				isAuthCompleted: true,
            };
		}
        case SET_USER_ROLE:
			let auxUser = {} as UserType;
			if(state.user){
				auxUser = {...state.user};
			}
            auxUser.role = action.payload;
            setSession(auxUser);
            return {
                ...state,
                user: auxUser,
				role: action.payload,
            }
		case SET_PERMISSIONS: {
			const { pagePermissions, actionPermissions } = action.payload;
			return {
				...state,
				pagePermissions,
				actionPermissions,
			};
		}
        default:
            return { ...state };
    }
};

export default Auth;
