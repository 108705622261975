import Bugsnag from '@bugsnag/js';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import Swal from 'sweetalert2';

import { AUTH_TYPE_GOOGLE } from '../redux/auth/constants';
import { UserType } from '../types';
import { setSession } from '../redux/auth/saga';
import { handleLogout } from './authUtils';

const currentEnv = window._CURRENT_ENV_;
// let backendUrl = `http://127.0.0.1:4010`;
let backendUrl = process.env.REACT_APP_BACKEND_URL_LOCAL;
if( currentEnv === 'DEV_ENV' ){
    backendUrl = process.env.REACT_APP_BACKEND_URL_STG;
}else if(currentEnv === 'PROD_ENV' || currentEnv === 'STG_ENV'){
    backendUrl = process.env.REACT_APP_BACKEND_URL_PROD;
}

const axiosClient = axios.create({
    baseURL: `${backendUrl}/api`,
});
type AxiosRequestConfigUpd = AxiosRequestConfig & { _retry?: boolean };

let isRefreshing = false;
let failedQueue: {
	resolve: Function;
	reject: Function;
}[] = [];

const processQueue = (error: Error | null, token: string | null) => {
	failedQueue.forEach(prom => {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve(token);
		}
	});
	failedQueue = [];
};

const refreshTokenIntenceptor = async (error: AxiosError) => {
	// If the response is a 401, redirect to login
	const originalRequest = error.config as AxiosRequestConfigUpd;
	if (error?.response?.status === 401 && !originalRequest._retry) {
		if (isRefreshing) {
			return new Promise(function(resolve, reject) {
				failedQueue.push({ resolve, reject });
			})
			.then(token => {
				originalRequest.headers['Authorization'] = 'Bearer ' + token;
				return axios(originalRequest);
			})
			.catch(err => {
				return Promise.reject(err);
			});
		}
		originalRequest._retry = true;
		isRefreshing = true;
		return new Promise(function(resolve, reject) {
			axios
				.get(`${backendUrl}/auth/google/refresh`, {
					headers: {
						Authorization: axiosClient.defaults.headers.common['Authorization']
					}
				})
				.then(({ data }) => {
					axiosClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
					originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
					processQueue(null, data.fooToken);
					const user: UserType = {
						id: data.id,
						username: data.username,
						email:  data.email,
						firstName: data.username,
						lastName: data.username,
						fullName: data.username,
						accessToken: data.access_token,
						type: AUTH_TYPE_GOOGLE,
						sessionExpirationTimestamp: new Date(data.token_expiry).getTime(),
					};
					setSession(user);
					resolve(axios(originalRequest));
				})
				.catch(err => {
					processQueue(err, null);
					console.log('Expired token', err);
					Swal.fire({ title: "Error", text: "Your session has ended. Please log in again", icon:"error" }).then(handleLogout);
					reject(err);
				})
				.then(() => {
					isRefreshing = false;
				});
		});
	}
	if (error?.response?.status === 403) {
		console.log(`You dont have pemission to invoke this endpoint: '${error?.response?.config?.url}`, error, error.response, error.response?.data);
		Bugsnag.notify(error);
		Swal.fire({ title: "Error", text: `You dont have pemission to invoke this endpoint: '${error?.response?.config?.url}'`, icon:"error" });
	}
	return Promise.reject(error);
};
axiosClient.interceptors.response.use(
	(response) => response,
	refreshTokenIntenceptor
);
export default axiosClient;
export const authGoogleWithCredentialsUrl = `${backendUrl}/auth/google/withCredentials`;