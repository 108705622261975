import React, {useEffect, useState} from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

// layout HOC
import withLayout from '../components/Layout';
import { allFlattenRoutes as routes } from './index';

import { getContractorData, initAuth } from '../redux/actions';
import { useHistory } from 'react-router-dom';
import { RootState } from '../redux/reducers';

type Props = {
	getContractorData: () => {};
	user: any;
	role: any;
}

const Routes = ({ getContractorData, user, role }: Props) => {
	const [isMounted, setIsMounted] = useState(false);
	const history = useHistory();
	const location = useLocation();
    useEffect(() => {//HERE CHARGING ALL APPLICATION DATA BEFORE INIT
        initAuth();
		if(user && !isMounted) {
            getContractorData();
			setIsMounted(true);
        } else if(!user && !isMounted && !['/revolutCallback'].includes(location.pathname)) {
			//If user is null and is not mounted we've to force login
			history.push('/account/login');
		}
        // eslint-disable-next-line
    }, [user, role]);

    return(
        // rendering the router with layout
        <Switch>
            {routes.map((route, index) => (
				!route.children && route.route && (
					<route.route
						key={index}
						path={route.path}
						roles={route.roles}
						exact={route.exact || false}
						component={withLayout((props: any) =>
							(route.component && <route.component {...props} />) || <></>
						)}></route.route>
				)
			))}
        </Switch>
    )
};
const mapStateToProps = (state: RootState) => {
	const { user, role } = state.Auth;
    return { user, role };
};
export default connect(mapStateToProps, { getContractorData, initAuth })(Routes);
