import React, { useState } from 'react'
import { Alert, Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { connect } from 'react-redux';
import fuzzysort from 'fuzzysort';
import Select from 'react-select';
import { Briefcase, Edit3, Trash2 } from 'react-feather';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import Bugsnag from '@bugsnag/js';
import { AxiosError } from 'axios';

import Loader from '../../../components/Loader';
import { defaultQueryConfig, getJobs, getSubcategories, updateJob, createJob } from '../../../helpers/api';
import JobsGrid from '../../components/AdminView/JobsGrid';
import PrivateCmp from '../../../helpers/PrivateCmp';
import { RootState } from '../../../redux/reducers';
import { Client, CreatingJob, JobType, NimblUserType, Recruiter } from '../../../types';
import ModalViewJob from '../../components/AdminView/ModalViewJob';

type Props = {
	nimblUser: null | NimblUserType & Recruiter;
	clients: Client[];
	clientsAreLoading: boolean;
};

const ManageJobsPage = ({
	nimblUser, clients, clientsAreLoading //FROM REDUX
}: Props) => {
	const { isLoading, error, data, isFetching } = useQuery<JobType[]>(['jobs'], () => getJobs(`is_active=true`), defaultQueryConfig);
	const queryClient = useQueryClient();
	const updateJobMutation = useMutation({
		mutationFn: ({id, job}: {id: number, job: any}) => updateJob(id, job),
		onSettled: () => {},
		onMutate: async ({id, job}) => {
			queryClient.cancelQueries({ queryKey: ['jobs'] });
			const previousJobs = queryClient.getQueryData(['jobs']);
			queryClient.setQueryData(['jobs'], (oldJobs?: JobType[]) => oldJobs?.map( _job => 
				_job.job_id === id ? { ...job, job_id: id } : _job
			));
			return { previousJobs };
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['jobs'] });
		},
		onError: (err, {id}, context) => {
			const error = err as AxiosError;
			console.log('Error updating job', err, error.response, error.response?.data);
			Bugsnag.notify(error);
			queryClient.setQueryData(['jobs'], context?.previousJobs);
			Swal.fire({title: "Error updating job", text: `Error updating job ${id}: ${err}, ${error.message}`, icon: "error"});
		},
	});
	const createJobMutation = useMutation({
		mutationFn: (job: CreatingJob) => createJob(job),
		onSettled: () => {},
		onMutate: async job => {
			queryClient.cancelQueries({ queryKey: ['jobs'] });
			// Snapshot the previous value
			const previousJobs = queryClient.getQueryData(['jobs']);
			// Optimistically update to the new value
			queryClient.setQueryData(['jobs'], (old?: CreatingJob[]) => old ? [...old, { ...job, job_id: uuidv4()}] : [{ ...job, job_id: uuidv4()}]);
			// Return a context object with the snapshotted value
			return { previousJobs };
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['jobs'] });
		},
		onError: (error, job, context) => {
			const err = error as AxiosError;
			console.log('Error creating job', err, err.response, err.response?.data);
			Bugsnag.notify(err);
			queryClient.setQueryData(['jobs'], context?.previousJobs);
			Swal.fire({title: "Error creating job", text: `Error creating job: ${err}, ${err.message}`, icon: "error"});
		},
	});
	const subCategoriesQuery = useQuery(['subcategories'], getSubcategories, defaultQueryConfig);
	const handleOpenViewModal = (job: JobType) => {
		toggleModalView(job);
		setEditModeIsActive(false);
		setSelectedJob(job);
	}
	const dataArr = Array.isArray(data) ? data.map(job => ({
		...job,
		job_title_action: <span className='nimblLink' onClick={() => handleOpenViewModal(job)}>
			{job.job_title}
		</span>,
		contact_full_name: `${job.contact?.contact_first_name} ${job.contact?.contact_last_name}`,
		actions : <>
			<PrivateCmp tag='update_job'>
				<span className='actionBtnSpan mr-1' onClick={() => handleEditJob(job)} title="Edit job">
					<Edit3 width={20} />
				</span>
				<span className='actionBtnSpan mr-1' onClick={() => handleDeleteJob(job)} title="Delete job">
					<Trash2 width={20} />
				</span>
			</PrivateCmp>
		</>
	})) : [];

	const [jobNameToSearch, setJobNameToSearch] = useState('');
	const [selectedClients, setSelectedClients] = useState<Client[]>([]);
	const [selectedJob, setSelectedJob] = useState<JobType|null>(null);
	const [modalViewIsOpen, setModalViewIsOpen] = useState(false);
	const [editModeIsActive, setEditModeIsActive] = useState(false);

	const toggleModalView = (job: JobType) => {
		setSelectedJob(job);	
		setModalViewIsOpen(!modalViewIsOpen);
	}

	const handleEditJob = (job: JobType) => {
		setSelectedJob(job);
		setModalViewIsOpen(true);
		setEditModeIsActive(true);
	}
	const handleAddJob = () => {
		setSelectedJob(null);
		setEditModeIsActive(true);
		setModalViewIsOpen(true);
	}
	const handleDeleteJob = async (job: JobType) => {
		const alertResult = await Swal.fire({
			icon: 'warning',
			title: 'Are you sure you want to delete this job?',
			showCancelButton: true,
			confirmButtonText: 'Delete',
			denyButtonText: `Cancel`,
		});
		if (!alertResult.isConfirmed) {
			return;
		}
		let newJob = {...job};
		delete newJob.job_id;
		delete newJob.client;
		delete newJob.contact;
		if(newJob.job_country) delete newJob.job_country;
		//@ts-ignore
		if(newJob.sub_category) delete newJob.sub_category;
		newJob.is_active = false;
		newJob = await updateJobMutation.mutateAsync({id: job.job_id || 0, job: newJob});
	}

	const handleClientChange = (localSelectedClients: Client[]) => {
        const clients = localSelectedClients ? localSelectedClients : [];
        setSelectedClients(clients);
    }
	
	const filterJobs = () => {
		//Filters jobs using client and jobs name
		let filteredJobs = dataArr;
		if (jobNameToSearch) {
			const results = fuzzysort.go(jobNameToSearch, filteredJobs,{
				limit: 20,
				keys: ['job_title', 'client.client_name', 'contact'],
			});
			filteredJobs = results.map(({obj}) => obj);
		}
		if(selectedClients && selectedClients.length){
			const selectedClientIds = selectedClients.map((c) => c.client_id);
			filteredJobs = filteredJobs.filter((job) =>
				job.contact?.client_id ? selectedClientIds.includes(job.contact.client_id) : false
        	);
		}
		return filteredJobs;
	};
	const handleUpsertJob = async (job: CreatingJob) => {
		let newJob = {...job};
		if(selectedJob){
			job.job_owner_id = selectedJob.job_owner_id;
			newJob = await updateJobMutation.mutateAsync({id: selectedJob.job_id || 0 ,job});
		}else{
			job.job_owner_id = nimblUser?.recruiter_id || 0;
			newJob = await createJobMutation.mutateAsync(job);
		}
		setSelectedJob(null);
		return newJob;
	}
	const handleCloseViewModal = () => {
		setModalViewIsOpen(false);
		setSelectedJob(null);
	}
	let jobs = filterJobs();
	return (
		<>
			<ModalViewJob
				editModeIsActive={editModeIsActive}
				modalIsOpen={modalViewIsOpen}
				clients={clients} subCategoriesData={subCategoriesQuery.data}
				jobToEdit={selectedJob}
				handleUpsert={handleUpsertJob}
				handleCloseModal={handleCloseViewModal}
				toggleModal={() => setModalViewIsOpen(!modalViewIsOpen)}
				setEditModeIsActive={setEditModeIsActive}
			/>
			<Row className="page-title align-items-center">
				<Col xs={12}>
					<h4 className="mb-1 mt-0">Manage Jobs</h4>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<>
						{error && <Alert color='danger' >There was an error, please reload the page</Alert>}
					</>
				</Col>
			</Row>
			<Row>
				<Col sm={12} lg={4}>
					<Card>
						<CardBody>
							<FormGroup>
								<Label for="jobNameToSearch">Job's name</Label>
								<Input type="text"
									value={jobNameToSearch}
									onChange={ e => setJobNameToSearch(e.target.value) } 
									name="jobNameToSearch" 
									id="jobNameToSearch"
								/>
							</FormGroup>
						</CardBody>
					</Card>
				</Col>
				<Col sm={12} lg={5}>
					<Card>
						{clientsAreLoading && <Loader />}
						<CardBody>
							<FormGroup>
								<Label for="clientSelect">Clients</Label>
								<Select
									isMulti={true}
									options={
										clients
										// clients.map(mappingClientToSelectValues)
									}
									className="react-select"
									classNamePrefix="react-select"
									getOptionLabel={option => option.client_name}
									getOptionValue={option => `${option.client_id}`}
									// value={
									// 	this.state.localSelectedClients &&
									// 	this.state.localSelectedClients.map(mappingClientToSelectValues)
									// }
									onChange={ e => handleClientChange(e as Client[])}
									id="clientSelect"></Select>
							</FormGroup>
						</CardBody>
					</Card>
				</Col>
				<Col sm={12} lg={1}>
					<Card>
						{(clientsAreLoading || isFetching || isLoading) && <Loader />}
						<CardBody>
							<FormGroup className='d-flex flex-column align-items-center'>
								<Label for="" className='invisibleText'>.</Label>
								<PrivateCmp tag='create_job'>
									<Button type='button' className='d-flex align-items-center'
										onClick={handleAddJob}
									>
										<span className="mr-1">+</span>
										<Briefcase />
									</Button>
								</PrivateCmp>
							</FormGroup>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					{(isFetching || isLoading) && <Loader />}
					<JobsGrid jobs={jobs} />
				</Col>
			</Row>
		</>
	)
}
const mapStateToProps = (state: RootState) => {
	return {
		clients: state.AdminDashboard.clients,
		clientsAreLoading: state.AdminDashboard.clientsAreLoading,
		nimblUser: state.Auth.nimblUser,
	}
}
export default connect(mapStateToProps, {})(ManageJobsPage);