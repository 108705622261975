/* CONTRACTOR DASHBOARD */
export const GET_CONTRACTOR = 'GET_CONTRACTOR';
export const GET_CONTRACTOR_SUCCESS = 'GET_CONTRACTOR_SUCCESS';
export const GET_CONTRACTOR_FAILED = 'GET_CONTRACTOR_FAILED';
export const SET_CONTRACTOR_LOADER = 'SET_CONTRACTOR_LOADER';

export const GET_CONTRACTOR_MONTH_DATA = 'GET_CONTRACTOR_MONTH_DATA';
export const GET_CONTRACTOR_MONTH_DATA_SUCCESS = 'GET_CONTRACTOR_MONTH_DATA_SUCCESS';
export const GET_CONTRACTOR_MONTH_DATA_FAILED = 'GET_CONTRACTOR_MONTH_DATA_FAILED';

export const SAVE_CONTRACTOR_MONTH_DATA = 'SAVE_CONTRACTOR_MONTH_DATA';
export const SAVE_CONTRACTOR_MONTH_DATA_SUCCESS = 'SAVE_CONTRACTOR_MONTH_DATA_SUCCESS';
export const SAVE_CONTRACTOR_MONTH_DATA_FAILED = 'SAVE_CONTRACTOR_MONTH_DATA_FAILED';

export const UPDATE_CONTRACTOR_DATA = 'UPDATE_CONTRACTOR_DATA';
export const UPDATE_CANDIDATE_DATA = 'UPDATE_CANDIDATE_DATA';

export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_MANAGER = 'ROLE_MANAGER';
export const ROLE_CONTRACTOR = 'ROLE_CONTRACTOR';
export const ROLE_RECRUITER = 'ROLE_RECRUITER';

export const SET_PROCESSED_MONTHS = 'SET_PROCESSED_MONTHS';

export const GET_CANDIDATE_DOCUMENTS = 'GET_CANDIDATE_DOCUMENTS';
export const GET_CANDIDATE_DOCUMENTS_SUCCESS = 'GET_CANDIDATE_DOCUMENTS_SUCCESS';
export const GET_CANDIDATE_DOCUMENTS_FAILED = 'GET_CANDIDATE_DOCUMENTS_FAILED';

export const SAVE_CANDIDATE_DOCUMENT = 'SAVE_CANDIDATE_DOCUMENT';
export const SAVE_CANDIDATE_DOCUMENT_SUCCESS = 'SAVE_CANDIDATE_DOCUMENT_SUCCESS';
export const SAVE_CANDIDATE_DOCUMENT_FAILED = 'SAVE_CANDIDATE_DOCUMENT_FAILED';