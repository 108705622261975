import React, { Component } from 'react';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Routes from './routes/Routes';

// Themes
// default
import './assets/scss/theme.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleAuthConfiguration } from './helpers/authUtils';

// dark
// import './assets/scss/theme-dark.scss';

// rtl
// import './assets/scss/theme-rtl.scss';

const queryClient = new QueryClient();
class App extends Component {
  render() {
    return( 
		<QueryClientProvider client={queryClient}>
			<GoogleOAuthProvider clientId={googleAuthConfiguration.clientId || ""}>
				<Routes />
				<ReactQueryDevtools initialIsOpen={false} />
			</GoogleOAuthProvider>;
		</QueryClientProvider>
	);
  }
}

export default App;
