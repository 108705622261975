import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import {
    Button,
    Col,
    CustomInput,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
} from 'reactstrap';
import Swal from 'sweetalert2';
import Bugsnag from '@bugsnag/js';
import { AxiosError } from 'axios';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';

import { NotificationType } from '../../../types';
import axiosClient from '../../../helpers/Axios';
import emailJson from '../../../helpers/initialEmailJson';


type Props = {
    notificationType: NotificationType | null;
    isOpen: boolean;
    toggleModal: () => void;
    refetch: () => void;
};
const EMPTY_NOTIFICATION_TYPE = {
    notification_type_name: '',
    recipient_type: '',
    should_send_email: false,
    email_template: '',
	email_template_json: '',
};
const ModalUpdateNotificationType = ({ notificationType, isOpen, toggleModal, refetch }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [newNotificationType, setNewNotificationType] = useState(EMPTY_NOTIFICATION_TYPE);
    const [editorIsReady, setEditorIsReady] = useState(false);

    useEffect(() => {
        if (isOpen && notificationType) {
            setNewNotificationType({
                notification_type_name: notificationType.notification_type_name || '',
                recipient_type: notificationType.recipient_type || '',
                should_send_email: notificationType.should_send_email || false,
                email_template: notificationType.email_template || '',
				email_template_json: notificationType.email_template_json || '',
            });
        }
    }, [notificationType, isOpen]);
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setNewNotificationType({
            ...newNotificationType,
            [e.target.name]: e.target.value,
        });
    };
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isLoading) return;
        setIsLoading(true);
        try {
			const _newNotificationType = {
				...newNotificationType,
				email_template: await getHtml(),
				email_template_json: JSON.stringify(await getDesign()),
			}
            await axiosClient.put(`/notificationtype/${notificationType?.notification_type_id}`, _newNotificationType);
            refetch();
            toggleModal();
            setNewNotificationType(EMPTY_NOTIFICATION_TYPE);
        } catch (err) {
            const error = err as AxiosError;
            console.log(error, error?.response, error?.response?.data);
            Bugsnag.notify(error);
            Swal.fire({ title: 'Error', text: 'Error updating type', icon: 'error' });
        }
        setIsLoading(false);
    };
    const emailEditorRef = useRef<EditorRef>(null);
	const getHtml = () => new Promise((resolve, reject) => {
		const unlayer = emailEditorRef.current?.editor;
        unlayer?.exportHtml((data) => {
            const { html } = data;
            resolve(html);
        });
		reject('Error getting html');
    });
	const getDesign = () => new Promise((resolve, reject) => {
		const unlayer = emailEditorRef.current?.editor;
        unlayer?.saveDesign((design: Object) => {
            resolve(design);
        });
		reject('Error getting design');
	});
    const onReady: EmailEditorProps['onReady'] = (unlayer) => {
		if(notificationType?.email_template_json){
			unlayer.loadDesign(JSON.parse(notificationType.email_template_json));
		}else{
			unlayer.loadDesign(emailJson);
		}
        setEditorIsReady(true);
    };
    if (!notificationType) return null;
    return (
        <Modal isOpen={isOpen} toggle={toggleModal} size="xl">
            <ModalHeader toggle={toggleModal}>Update notification type</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Row>
                            <Col sm={4}>
                                <FormGroup>
                                    <Label htmlFor="notification_type_name">Type name</Label>
                                    <Input
                                        id="notification_type_name"
                                        type="text"
                                        name="notification_type_name"
                                        required
                                        value={newNotificationType.notification_type_name}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup>
                                    <Label htmlFor="recipient_type">Recipient</Label>
                                    <Input
                                        id="recipient_type"
                                        type="select"
                                        name="recipient_type"
                                        value={newNotificationType.recipient_type}
                                        onChange={handleChange}>
                                        <option value="Contractor">Contractor</option>
                                        <option value="Client">Client</option>
                                        <option value="Recruiter">Recruiter</option>
                                        <option value="Admin">Admin</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col>
                                <Label className="invisibleText">Should send email</Label>
                                <CustomInput
                                    type="switch"
                                    id="should_send_email"
                                    name="should_send_email"
                                    label="Should send mail"
                                    // value={editedExpense.is_reconciled}
                                    checked={newNotificationType.should_send_email}
                                    onChange={() =>
                                        setNewNotificationType({
                                            ...newNotificationType,
                                            should_send_email: newNotificationType.should_send_email ? false : true,
                                        })
                                    }
                                />
                            </Col>
                            {newNotificationType.should_send_email && (
                                <Col sm={12}>
                                    <EmailEditor ref={emailEditorRef} onReady={onReady} />
                                </Col>
                            )}
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Row>
                            <Col xs={12}>
                                {/* <Button onClick={exportHtml}>Save html</Button> */}
                                <Button type="submit" disabled={isLoading || editorIsReady}>
                                    {isLoading ? <Spinner size="sm" /> : 'Update'}
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default ModalUpdateNotificationType;
