import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './redux/store';

Bugsnag.start({
	apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || "",
	plugins: [new BugsnagPluginReact()]
});
const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

const Parent = ErrorBoundary || React.Fragment;

let basename = '';
if(window.location.href.includes('nimbl.ai') && !window.location.href.includes('admin')){
	basename = '/adminportal';
}
const store = configureStore({});

ReactDOM.render(
	<Parent>
		<Provider store={store}>
			<BrowserRouter basename={basename}>
				<App />
			</BrowserRouter>
		</Provider>
	</Parent>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

