import React, { useEffect, useState } from 'react';
import { EditorState, RawDraftContentState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

type Props = {
	handleChange: (s: string) => void;
	initialHtml?: string;
};

const RichTextEditor = ({ handleChange, initialHtml }: Props) => {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
	const handleContentChange = (content: RawDraftContentState) => {
		const html = draftToHtml(content);
		handleChange(html);
	}
	useEffect(() => {
		if(initialHtml){
			const contentBlock = htmlToDraft(initialHtml);
			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				const editorState = EditorState.createWithContent(contentState);
				setEditorState(editorState);
			}
		}else{
			setEditorState(EditorState.createEmpty());
		}
	}, [initialHtml]);
    return (
        <div className="App">
            <Editor editorState={editorState} 
				onEditorStateChange={setEditorState} 
				editorClassName='richTextEditor'
				onContentStateChange={handleContentChange}
				toolbar={{
					options: ['inline', 'list', 'embedded', 'emoji', 'remove', 'history'],
				}}
			/>
        </div>
    );
};

export default RichTextEditor;
