/* Layout types and other constants */

export const LAYOUT_VERTICAL = 'vertical';
export const LAYOUT_HORIZONTAL = 'topnav';
export type LayoutOrientationType = 'vertical' | 'topnav';

export const LAYOUT_WIDTH_FLUID = 'fluid';
export const LAYOUT_WIDTH_BOXED = 'boxed';
export type LayoutWidthType = 'fluid' | 'boxed';

export const LEFT_SIDEBAR_THEME_DEFAULT = 'default';
export const LEFT_SIDEBAR_THEME_DARK = 'dark';
export type LefSidebarThemeType = 'default' | 'dark';

export const LEFT_SIDEBAR_TYPE_FIXED = 'fixed';
export const LEFT_SIDEBAR_TYPE_CONDENSED = 'condensed';
export const LEFT_SIDEBAR_TYPE_SCROLLABLE = 'scrollable';
export type LefSidebarType = 'fixed' | 'condensed' | 'scrollable';