// @flow
import {
    INIT_MENU,
    INIT_MENU_SUCCESS,
    CHANGE_ACTIVE_MENU_FROM_LOCATION,
    CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS,
    ADD_MENU_ITEM,
} from './constants';
import assignIds from './utils';

const AppMenu = (state = {}, action) => {
    switch (action.type) {
        case INIT_MENU:
            return action.payload;
        case INIT_MENU_SUCCESS:
            return { ...state, ...action.payload };
        case CHANGE_ACTIVE_MENU_FROM_LOCATION:
            return { ...state };
        case CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS:
            return { ...state, ...action.payload };
        case ADD_MENU_ITEM: {
            let currentMenuItems = [];
            if(state.menuItems){
                currentMenuItems.push( ...state.menuItems)
            }
            const route = currentMenuItems.find( item => item.path === action.payload.path );
            if(!route){
                currentMenuItems.push(action.payload);
                currentMenuItems = assignIds(currentMenuItems);
            }
            return {
                ...state, 
                menuItems: currentMenuItems
            };
        }default:
            return state;
    }
};

export default AppMenu;
