import Bugsnag from '@bugsnag/js';
import React, { ChangeEvent, FormEvent, useEffect } from 'react';
import { useState } from 'react';
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { AxiosError, AxiosInstance } from 'axios';
import Select from 'react-select';
import { useQuery } from '@tanstack/react-query';

import Loader from '../../../components/Loader';
import { enforceDateFormat } from '../../../helpers';
import axiosClient from '../../../helpers/Axios';
import { Client, Contact, Country, CreatingJob, JobType, SubCategory } from '../../../types';
import { saveAudit } from '../../../helpers/audit';
import RichTextEditor from '../RichTextEditor';
import PrivateCmp from '../../../helpers/PrivateCmp';
import { defaultQueryConfig, getCountries } from '../../../helpers/api';

const LATAM_COUNTRY_IDS = [32, 144, 240, 52, 173, 27, 69, 243, 159, 104, 179, 56, 178, 68, 96, 71, 172, 59, 11, 46];

const CLEAN_JOB: CreatingJob = {
    // job_owner_id: ,
    primary_contact_id: 0,
    expiry_date: '',
    job_description: '',
    job_summary: '',
    job_title: '',
    job_url: '',
    sub_category_id: 0,
    job_owner_id: 0,
    client_id: 0,
};
type Props = {
    modalIsOpen: boolean;
    clients: Client[];
    subCategoriesData: SubCategory[];
    jobToEdit: JobType | null;
    editModeIsActive: boolean;
    handleUpsert: (job: CreatingJob) => Promise<any>;
    handleCloseModal: () => void;
    toggleModal: () => void;
    setEditModeIsActive: (value: boolean) => void;
};
const ModalViewJob = ({
    modalIsOpen,
    clients,
    subCategoriesData,
    jobToEdit,
    editModeIsActive,
    handleUpsert,
    handleCloseModal,
    toggleModal,
    setEditModeIsActive,
}: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [clientId, setClientId] = useState('');
    const [contacts, setContacts] = useState<Contact[]>([]);
    const [contactsAreLoading, setContactsAreLoading] = useState(false);
    const [job, setJob] = useState(CLEAN_JOB);
	const [selectedJobCountryIds, setSelectedJobCountryIds] = useState<number[]>([]);
	const countriesQuery = useQuery<Country[]>([`countries`], getCountries, defaultQueryConfig);
    useEffect(() => {
        setContacts([]);
        const getClient = async () => {
            setContactsAreLoading(true);
            try {
                const contactResponse = await axiosClient.get(`/contacts?client_id=${clientId}`);
                if (contactResponse?.data?.length) {
                    setContacts(contactResponse.data);
                }
            } catch (err) {
                const error = err as AxiosError;
                console.log('Error getting contacts', error, error.response, error.response?.data);
                Bugsnag.notify(error);
                Swal.fire({
                    title: 'Error getting contacts',
                    text: 'Please refresh the page and try again',
                    icon: 'warning',
                });
            }
            setContactsAreLoading(false);
        };
        if (clientId) {
            getClient();
        }
    }, [clientId]);
    useEffect(() => {
        if (jobToEdit) {
            if (jobToEdit.client?.client_id) {
                setClientId(`${jobToEdit.client.client_id}`);
            }
            setJob({
                primary_contact_id: jobToEdit.primary_contact_id,
                expiry_date: enforceDateFormat(jobToEdit.expiry_date) || '',
                job_description: jobToEdit.job_description,
                job_summary: jobToEdit.job_summary,
                job_title: jobToEdit.job_title,
                job_url: jobToEdit.job_url,
                sub_category_id: jobToEdit.sub_category_id,
                job_owner_id: jobToEdit.job_owner_id,
                client_id: jobToEdit.client_id,
            });
			setSelectedJobCountryIds(jobToEdit.job_country?.map(c => c.country_id) || []);
        } else {
            setClientId('');
            setJob(CLEAN_JOB);
			setSelectedJobCountryIds([]);
        }
    }, [jobToEdit]);
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setJob({
            ...job,
            [e.target.name]: e.target.value,
        });
    };
    const selectedClient = clients?.find((c) => c.client_id === Number(clientId));
    const relatedContact = contacts.find((c) => c.contact_id === Number(job.primary_contact_id));
    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const _newJob = { ...job };
        const contact = contacts.find(c => c.contact_id === Number(_newJob.primary_contact_id));
        if(!contact){
        	Swal.fire({title: 'Error', text: 'Error creating job, please select a contact', icon: 'warning'})
        	return;
        }
        _newJob.client_id = contact.client_id;
        setIsLoading(true);
        const newJob = await handleUpsert(_newJob);
        setIsLoading(false);
        saveAudit({
        	action_id: jobToEdit ? 22 : 21,
        	before_data: jobToEdit ? jobToEdit : null,
        	after_data: { ...newJob },
        	error: '',
        	observations: jobToEdit? `Job updated: ${newJob.job_title}` :`Job created: ${newJob.job_title} for client ${selectedClient?.client_name}`,
        	datetime_start: new Date().toISOString(),
        	has_error: false,
        });
		const jobContPromises: Promise<AxiosInstance>[] = [];
		const newIds = [...selectedJobCountryIds];
		if(jobToEdit){
			jobToEdit.job_country?.forEach(jc => {
				if(!newIds.includes(jc.country_id)){
					jobContPromises.push(
						axiosClient.delete(`/jobcountry/${jc.job_country_id}`)
					);
				}else {
					newIds.splice(newIds.indexOf(jc.country_id), 1);
				}
			});
		}
		newIds.forEach(countryId => {
			jobContPromises.push(
				axiosClient.post(`/jobcountry`, {
					job_id: newJob.job_id,
					country_id: countryId
				})
			);
		});
		try {
			await Promise.all(jobContPromises);	
		} catch (err) {
			const error = err as AxiosError;
			Swal.fire({ title: 'Error', text: 'Error saving job countries, please try again', icon: 'warning'});
			console.log(error, error.response, error.response?.data);
			Bugsnag.notify(error);
		}
        setJob(CLEAN_JOB);
        handleCloseModal();
    };
    const handleGoToEdit = () => {
        setEditModeIsActive(true);
    };
	const handleClickLatam = () => {
		setSelectedJobCountryIds(LATAM_COUNTRY_IDS);
	}
	// const handleClickEurope = () => {
	// 	setSelectedJobCountryIds([62, 81, 91, 105, 1114, 176])
	// }
	const _isLoading = isLoading || countriesQuery.isLoading || countriesQuery.isFetching;

    return (
        <Modal isOpen={modalIsOpen} toggle={handleCloseModal} size="xl">
            {_isLoading && <Loader />}
            {(editModeIsActive) ? (
                <>
                    <ModalHeader toggle={handleCloseModal}>
                        {jobToEdit ? 'Edit existing job' : 'Add new job'}
                    </ModalHeader>
                    <Form onSubmit={handleSubmit}>
                        <ModalBody>
                            <Row>
                                <Col sm={12} md={6}>
                                    <FormGroup>
                                        <Label htmlFor="job_title">
                                            Job title<span className="text-danger">(*)</span>
                                        </Label>
                                        <Input
                                            id="job_title"
                                            type="text"
                                            name="job_title"
                                            onChange={handleChange}
                                            value={job.job_title}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={6}>
                                    <FormGroup>
                                        <Label htmlFor="job_url">
                                            Job url<span className="text-danger">(*)</span>
                                        </Label>
                                        <Input
                                            id="job_url"
                                            type="text"
                                            name="job_url"
                                            onChange={handleChange}
                                            value={job.job_url}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={3}>
                                    <FormGroup>
                                        <Label htmlFor="expiry_date">
                                            Expiring date<span className="text-danger">(*)</span>
                                        </Label>
                                        <Input
                                            id="expiry_date"
                                            type="date"
                                            name="expiry_date"
                                            onChange={handleChange}
                                            value={job.expiry_date}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={3}>
                                    <FormGroup>
                                        <Label htmlFor="sub_category_id">
                                            Subcategory<span className="text-danger">(*)</span>
                                        </Label>
                                        <Input
                                            type="select"
                                            id="sub_category_id"
                                            name="sub_category_id"
                                            onChange={handleChange}
                                            value={job.sub_category_id}
                                            required>
                                            <option> -- Pick a category -- </option>
                                            {subCategoriesData?.map((subCat) => (
                                                <option
                                                    key={`subCat-${subCat.sub_category_id}`}
                                                    value={subCat.sub_category_id}>
                                                    {subCat.sub_category_name}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={3}>
                                    <FormGroup>
                                        <Label htmlFor="client_select">
                                            Client <span className="text-danger">(*)</span>
                                        </Label>
                                        <Input
                                            type="select"
                                            id="client_select"
                                            name="client_select"
                                            onChange={(e) => setClientId(e.target.value)}
                                            value={clientId}>
                                            <option value=""> -- Pick a client -- </option>
                                            {clients?.map((client) => (
                                                <option key={`client-${client.client_id}`} value={client.client_id}>
                                                    {client.client_name}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={3}>
                                    <FormGroup>
                                        <Label htmlFor="primary_contact_id">
                                            Contact <span className="text-danger">(*)</span>
                                        </Label>
                                        {contactsAreLoading ? (
                                            <div className="form-control">
                                                <Spinner size="sm" />
                                            </div>
                                        ) : (
                                            <Input
                                                type="select"
                                                id="primary_contact_id"
                                                name="primary_contact_id"
                                                disabled={clientId === ''}
                                                onChange={handleChange}
                                                value={job.primary_contact_id}
                                                required>
                                                <option> -- Pick a contact -- </option>
                                                {contacts?.map((contact) => (
                                                    <option
                                                        key={`contact-${contact.contact_id}`}
                                                        value={contact.contact_id}>
                                                        {`${contact.contact_first_name} ${contact.contact_last_name}`}
                                                    </option>
                                                ))}
                                            </Input>
                                        )}
                                    </FormGroup>
                                </Col>
							</Row>
							<Row>
								<Col sm={12}>
									<FormGroup>
										<Label for="countries">Country</Label>
										<div className='row'>
											<div className='col-md-8'>
												<Select
													isMulti
													options={countriesQuery.data || []}
													className="react-select"
													classNamePrefix="react-select"
													getOptionLabel={(option: Country) => option.country_name}
													getOptionValue={(option: Country) => `${option.country_id}`}
													value={countriesQuery.data?.filter(c => selectedJobCountryIds.includes(c.country_id))}
													onChange={e => setSelectedJobCountryIds(e.map(c => c.country_id))}
													id="countries"
												/>
											</div>
											<div className='col d-flex align-items-center'>
												<Button onClick={handleClickLatam} className='mr-2'>Latam</Button>
												{/* <Button onClick={handleClickEurope}>Europe</Button> */}
											</div>
										</div>
									</FormGroup>
								</Col>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label for="job_summary">Job summary</Label>
                                        <Input
                                            type="textarea"
                                            id="job_summary"
                                            name="job_summary"
                                            onChange={handleChange}
                                            value={job.job_summary}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label for="job_description">Job description(HTML)</Label>
                                        <RichTextEditor
                                            handleChange={(jobHtml) => setJob({ ...job, job_description: jobHtml })}
                                            initialHtml={jobToEdit?.job_description}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Row>
                                <Col sm={12} className="d-flex justify-content-end">
                                    <Button type="submit">Save</Button>
                                </Col>
                            </Row>
                        </ModalFooter>
                    </Form>
                </>
            ) : (
                <>
                    <ModalHeader toggle={handleCloseModal}>
                        {jobToEdit ? 'Edit existing job' : 'Add new job'}
                    </ModalHeader>
                    <Form>
                        <ModalBody>
                            <Row>
                                <Col sm={12} md={6}>
                                    <FormGroup>
                                        <Label htmlFor="job_title">
                                            Job title<span className="text-danger">(*)</span>
                                        </Label>
                                        <div className="job-attr-displayed">{job.job_title}</div>
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={6}>
                                    <FormGroup>
                                        <Label htmlFor="job_url">
                                            Job url<span className="text-danger">(*)</span>
                                        </Label>
                                        <div className="job-attr-displayed">
                                            <a href={job.job_url} target="_blank" rel="noopener noreferrer">
                                                {job.job_url}
                                            </a>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={3}>
                                    <FormGroup>
                                        <Label htmlFor="expiry_date">
                                            Expiring date<span className="text-danger">(*)</span>
                                        </Label>
                                        <div className="job-attr-displayed">{job.expiry_date}</div>
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={3}>
                                    <FormGroup>
                                        <Label htmlFor="sub_category_id">
                                            Subcategory<span className="text-danger">(*)</span>
                                        </Label>
                                        <div className="job-attr-displayed">
                                            {subCategoriesData?.find((sc) => sc.sub_category_id === job.sub_category_id)
                                                ?.sub_category_name || ''}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={3}>
                                    <FormGroup>
                                        <Label htmlFor="client_select">
                                            Client <span className="text-danger">(*)</span>
                                        </Label>
                                        <div className="job-attr-displayed">{selectedClient?.client_name || ''}</div>
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={3}>
                                    <FormGroup>
                                        <Label htmlFor="primary_contact_id">
                                            Contact <span className="text-danger">(*)</span>
                                        </Label>
                                        <div className="job-attr-displayed">
                                            {relatedContact?.contact_first_name} {relatedContact?.contact_last_name}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label for="job_summary">Job summary</Label>
                                        <div className="job-attr-displayed">{job.job_summary}</div>
                                    </FormGroup>
                                </Col>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label for="job_description">Job description(HTML)</Label>
                                        <div
                                            className="job-description-viewer"
                                            dangerouslySetInnerHTML={{ __html: job.job_description }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Row>
                                <Col sm={12} className="d-flex justify-content-end">
                                    <PrivateCmp tag="update_job">
                                        <Button type="button" onClick={handleGoToEdit}>
                                            Edit
                                        </Button>
                                    </PrivateCmp>
                                </Col>
                            </Row>
                        </ModalFooter>
                    </Form>
                </>
            )}
        </Modal>
    );
};

export default ModalViewJob;
