import React from 'react';
// import { PopoverBody, UncontrolledPopover } from 'reactstrap';
// import { PopoverLine } from '../../../helpers';

const PaymentCell = ({icon, text, contractor, idStr}) => {
	return (
		<>
			<div className='d-flex align-items-center' id={idStr}>
				<span className='mr-1'>{text}</span>
				<span>{icon}</span>
			</div>
			{/* {
				intermediary_bank_swift && 
				<UncontrolledPopover
					className='contractorPopover'
					target={idStr}
					trigger='hover'
					placement='left'
				>
					<PopoverBody className='contractorPopoverBody'>
						<PopoverLine title='Int. Bank Account' value={intermediary_bank_account_number} />
						<PopoverLine title='Int. Bank Swift' value={intermediary_bank_swift} />
					</PopoverBody>
				</UncontrolledPopover>
			} */}
		</>
	);
};

export default PaymentCell;
