import React from 'react';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';

import { ROLE_ADMIN, ROLE_MANAGER } from '../redux/contractorView/constants';
import ManageJobsPage from '../contractorApp/pages/AdminView/ManageJobsPage';
import PrivateRouteConnected from './PrivateRoute';
import RevolutCallback from '../contractorApp/pages/AdminView/HandleRevolutCallback';
import { CustomRoute } from '../types';
import { PAGES } from '../helpers';
import NotificationTypePage from '../contractorApp/pages/AdminView/NotificationTypePage';

// auth
//const Login = React.lazy(() => import('../pages/auth/Login'));
const Login = React.lazy(() => import('../contractorApp/pages/Login'));
const RootPage = React.lazy(() => import('../contractorApp/pages/RootPage'));
const Logout = React.lazy(() => import('../contractorApp/pages/Logout'));

const DashboardAdmin = React.lazy(() => import('../contractorApp/pages/AdminView/AdminDashboard'));
const CreateContractor = React.lazy(() => import('../contractorApp/pages/AdminView/CreateContractor'));
const AdminOverview = React.lazy( () => import('../contractorApp/pages/AdminView/AdminOverview') );
const ChangeContract = React.lazy(() => import('../contractorApp/pages/AdminView/ChangeContractDetails'));
const GenerateClientInvoice = React.lazy(() => import('../contractorApp/pages/AdminView/ClientInvoicesPage'));
const AdvancedSearchPage = React.lazy(() => import('../contractorApp/pages/AdminView/AdvancedSearchPage'));
const ClientAdminPage = React.lazy(() => import('../contractorApp/pages/AdminView/ClientAdminPage'));
const ExpensesPage = React.lazy(() => import('../contractorApp/pages/AdminView/Expenses'));
const ExpensesCategoriesPage = React.lazy(() => import('../contractorApp/pages/AdminView/ExpenseCategories'));
const ExpensePayMethodPage = React.lazy(() => import('../contractorApp/pages/AdminView/ExpensePayMethods'));
const UploadBankReportPage = React.lazy(() => import('../contractorApp/pages/AdminView/UploadBankReport'));
const RecruiterMonthPage = React.lazy(() => import('../contractorApp/pages/AdminView/RecruiterMonthPage'));
const RecruitersPage = React.lazy(() => import('../contractorApp/pages/AdminView/RecruiterPage'));

const RecruiterPayBatchPage = React.lazy(() => import('../contractorApp/pages/AdminView/RevolutPaymentRecruiterBatch'));
const ContractorPayBatchPage = React.lazy(() => import('../contractorApp/pages/AdminView/RevolutPaymentContractorBatch'));
const RevolutAccountsPage = React.lazy(() => import('../contractorApp/pages/AdminView/RevolutAccountsPage'));

const ManagePaperworkPage = React.lazy(() => import('../contractorApp/pages/AdminView/ManagePaperworkProgress'));
const AuditPage = React.lazy(() => import('../contractorApp/pages/AdminView/AuditPage'));
const ReferralsPage = React.lazy(() => import('../contractorApp/pages/common/ReferralsPage'));
const CreateReferralPage = React.lazy(() => import('../contractorApp/pages/common/CreateReferralPage'));
const ReferralDetailPage = React.lazy(() => import('../contractorApp/pages/common/ReferralDetailPage'));
//END ROUTES CONTRACTOR APP

// pages
const Error404 = React.lazy(() => import('../contractorApp/pages/Error404'));

// handle auth and authorization

// root routes
let routeId = 0;
const rootRoute: CustomRoute = {
    path: '/',
    exact: true,
    component: RootPage,
    route: PrivateRouteConnected,
	id: routeId++,
};

export const dashboardAdminRoute: CustomRoute = {
    path: '/admin/dashboard',
    name: 'Month end',
    icon: FeatherIcon.Calendar,
    component: DashboardAdmin,
    roles: [ROLE_ADMIN],
    route: PrivateRouteConnected,
	id: routeId++,
};
export const createContractorRoute: CustomRoute = {
    path: '/admin/createContractor',
    name: 'Candidates',
    icon: FeatherIcon.UserPlus,
    component: CreateContractor,
    roles: [ROLE_ADMIN],
    route: PrivateRouteConnected,
	id: routeId++,
};
export const adminOverViewRoute: CustomRoute = {
    path: '/admin/overview',
    name: 'Overview',
    icon: FeatherIcon.Home,
    component: AdminOverview,
    roles: [ROLE_ADMIN, ROLE_MANAGER],
    route: PrivateRouteConnected,
	id: routeId++,
};
export const changeContractRoute: CustomRoute = {
	path: '/admin/changeContract',
    name: 'Change contract',
    icon: FeatherIcon.Clipboard,
    component: ChangeContract,
    roles: [ROLE_ADMIN],
    route: PrivateRouteConnected,
	id: routeId++,
};
export const advancedSearchRoute: CustomRoute = {
	path: '/admin/advancedSearch',
	name: 'Advanced search',
	icon: FeatherIcon.Search,
	component: AdvancedSearchPage,
	roles: [ROLE_ADMIN],
	route: PrivateRouteConnected,
	id: routeId++,
};
export const jobsRoute: CustomRoute = {
	path: '/admin/jobs',
	name: 'Manage Jobs',
	icon: FeatherIcon.Briefcase,
	component: ManageJobsPage,
	roles: [ROLE_ADMIN, ROLE_MANAGER],
	route: PrivateRouteConnected,
	id: routeId++,
};
export const recruiterRoutes = {
    path: '/admin/recruiter',
    name: 'Employee',
    icon: FeatherIcon.UserCheck,
	id: routeId++,
    children: [
        {
			id: routeId++,
			path: '/admin/recruiter/manage',
			name: 'Manage employees',
			component: RecruitersPage,
			roles: [ROLE_ADMIN],
			route: PrivateRouteConnected,
		},
		{
			id: routeId++,
			path: '/admin/recruiter/invoices',
			name: 'Manage employee invoices',
			component: RecruiterMonthPage,
			roles: [ROLE_ADMIN],
			route: PrivateRouteConnected,
		}
    ]
};
export const clientRoutes: CustomRoute = {
    path: PAGES.CLIENT_PAGE,
    name: 'Client',
    icon: FeatherIcon.Users,
	id: routeId++,
    children: [
        {
			path: '/admin/client/manage',
			name: 'Manage clients',
			// icon: FeatherIcon.Users,
			component: ClientAdminPage,
			roles: [ROLE_ADMIN, ROLE_MANAGER],
			route: PrivateRouteConnected,
			id: routeId++,
		},
		{
			path: PAGES.CLIENT_INVOICES_PAGE,
			name: 'Client invoices',
			// icon: FeatherIcon.FileText,
			component: GenerateClientInvoice,
			roles: [ROLE_ADMIN],
			route: PrivateRouteConnected,
			id: routeId++,
		},
    ]
};
export const expenseRoutes = {
    path: '/admin/expense',
    name: 'Expense',
    icon: FeatherIcon.DollarSign,
	id: routeId++,
    children: [
        {
			path: '/admin/expense/manage',
			name: 'Manage expenses',
			component: ExpensesPage,
			roles: [ROLE_ADMIN],
			route: PrivateRouteConnected,
			id: routeId++,
		},
		{
			path: '/admin/expense/payMethods',
			name: 'Manage payment methods',
			component: ExpensePayMethodPage,
			roles: [ROLE_ADMIN],
			route: PrivateRouteConnected,
			id: routeId++,
		},
		{
			path: '/admin/expense/categories',
			name: 'Manage categories',
			component: ExpensesCategoriesPage,
			roles: [ROLE_ADMIN],
			route: PrivateRouteConnected,
			id: routeId++,
		},
    ]
};
export const conciliationRoute = {
	path: '/admin/conciliation',
	name: 'Conciliation',
	icon: FeatherIcon.UploadCloud,
	component: UploadBankReportPage,
	roles: [ROLE_ADMIN],
	route: PrivateRouteConnected,
	id: routeId++,
}
export const payRecruitersRoute = {
	path: '/admin/revolut/payRecruiters',
	name: 'Pay recruiters',
	icon: FeatherIcon.CreditCard,
	component: RecruiterPayBatchPage,
	roles: [ROLE_ADMIN],
	route: PrivateRouteConnected,
	id: routeId++,
}
export const payContractorsRoute = {
	path: '/admin/revolut/payContractors',
	name: 'Pay contractors',
	icon: FeatherIcon.CreditCard,
	component: ContractorPayBatchPage,
	roles: [ROLE_ADMIN],
	route: PrivateRouteConnected,
	id: routeId++,
}
export const revolutAccountsRoute = {
	path: '/admin/revolut/accounts',
	name: 'Manage revolut accounts',
	// icon:,
	component: RevolutAccountsPage,
	roles: [ROLE_ADMIN],
	route: PrivateRouteConnected,
	id: routeId++,
};

export const managePaperworkRoute: CustomRoute = {
	id: routeId++,
    path: '/admin/paperwork',
    name: 'Paperwork',
    icon: FeatherIcon.FileText,
    component: ManagePaperworkPage,
    roles: [ROLE_ADMIN, ROLE_MANAGER],
    route: PrivateRouteConnected
};
export const auditLogRoute: CustomRoute = {
	id: routeId++,
    path: '/admin/auditlogs',
    name: 'Audit',
    icon: FeatherIcon.PlayCircle,
    component: AuditPage,
    roles: [ROLE_ADMIN, ROLE_MANAGER],
    route: PrivateRouteConnected
};

export const referralRoutes = {
    path: PAGES.REFERRALS_PAGE,
    name: 'Referrals',
    icon: FeatherIcon.PhoneCall,
	id: routeId++,
    children: [
        {
			id: routeId++,
			path: PAGES.REFERRALS_TABLE_PAGE,
			name: 'View referrals',
			component: ReferralsPage,
			roles: [ROLE_ADMIN],
			route: PrivateRouteConnected
		},
		{
			id: routeId++,
			path: '/referrals/create',
			name: 'Refer a candidate',
			component: CreateReferralPage,
			roles: [ROLE_ADMIN],
			route: PrivateRouteConnected
		},
		{
			id: routeId++,
			path: '/referrals/:id',
			// name: 'Refer a candidate',
			component: ReferralDetailPage,
			roles: [ROLE_ADMIN],
			route: PrivateRouteConnected
		}
    ]
};
export const notificationTypeRoute: CustomRoute = {
	path: '/notificationtypes',
	name: 'Notification types',
	icon: FeatherIcon.Bell,
	component: NotificationTypePage,
	roles: [ROLE_ADMIN],
	route: PrivateRouteConnected,
	id: routeId++,
};

//END of contractor view

// pages
const pagesRoutes = {
    path: '/pages',
    name: 'Pages',
    header: 'Custom',
    icon: FeatherIcon.FileText,
	id: routeId++,
    children: [
        {
            path: '/pages/error-404',
            name: 'Error 404',
            component: Error404,
            route: Route,
			id: routeId++,
        },
    ]
};

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
	id: routeId++,
    children: [
        {
			id: routeId++,
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
		{
			id: routeId++,
			path: '/revolutCallback',
			name: 'Revolut callback',
			component: RevolutCallback,
			route: Route,
		},
        {
			id: routeId++,
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes: CustomRoute[]) => {
    let flatRoutes: CustomRoute[] = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes: CustomRoute[] = [
    rootRoute,
    pagesRoutes,
    authRoutes,
    adminOverViewRoute,
	changeContractRoute,
    dashboardAdminRoute,
    createContractorRoute,
	advancedSearchRoute,
    // contractorPages,
	clientRoutes,
	expenseRoutes,
	conciliationRoute,
	payRecruitersRoute,
	payContractorsRoute,
	revolutAccountsRoute,
	managePaperworkRoute,
	auditLogRoute,
	referralRoutes,
	jobsRoute,
	recruiterRoutes,
	notificationTypeRoute
];

const allFlattenRoutes = flattenRoutes(allRoutes);
const authProtectedRoutes: CustomRoute[] = [];
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
