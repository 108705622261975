/* ADMIN DASHBOARD */
export const INITIAL_LOAD_ADMIN_DB = 'INITIAL_LOAD_ADMIN_DB';
export const INITIAL_LOAD_ADMIN_DB_SUCCESS = 'INITIAL_LOAD_ADMIN_DB_SUCCESS';
export const INITIAL_LOAD_ADMIN_DB_FAILED = 'INITIAL_LOAD_ADMIN_DB_FAILED';
export const GET_ALL_CONTRACTORS = 'GET_ALL_CONTRACTORS';
export const GET_ALL_CONTRACTORS_SUCCESS = 'GET_ALL_CONTRACTORS_SUCCESS';
export const GET_ALL_CONTRACTORS_ERROR = 'GET_ALL_CONTRACTORS_ERROR';
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_ERROR = 'GET_CLIENTS_ERROR';
export const GET_POSSIBLE_INVOICE_STATUSES = 'GET_POSSIBLE_INVOICE_STATUSES';
export const GET_POSSIBLE_INVOICE_STATUSES_SUCCESS = 'GET_POSSIBLE_INVOICE_STATUSES_SUCCESS';
export const GET_POSSIBLE_INVOICE_STATUSES_ERROR = 'GET_POSSIBLE_INVOICE_STATUSES_ERROR';
export const UPDATE_CONTRACTOR_MONTH_RECORD = 'UPDATE_CONTRACTOR_MONTH_RECORD';
export const INIT_MONTH_END = 'INIT_MONTH_END';
export const INIT_MONTH_END_SUCCESS = 'INIT_MONTH_END_SUCCESS';
export const INIT_MONTH_END_FAILED = 'INIT_MONTH_END_FAILED';
export const GET_EXCHANGE_RATES = 'GET_EXCHANGE_RATES';
export const GET_EXCHANGE_RATES_SUCCESS = 'GET_EXCHANGE_RATES_SUCCESS';
export const GET_EXCHANGE_RATES_FAILED = 'GET_EXCHANGE_RATES_FAILED';
export const UPDATE_EXCHAGE_RATE = 'UPDATE_EXCHAGE_RATE';
export const UPDATE_EXCHAGE_RATE_SUCCESS = 'UPDATE_EXCHAGE_RATE_SUCCESS';
export const UPDATE_EXCHAGE_RATE_FAILED = 'UPDATE_EXCHAGE_RATE_FAILED';
export const GET_CONTRACTOR_MONTH_RECORDS = 'GET_CONTRACTOR_MONTH_RECORDS';
export const GET_CONTRACTOR_MONTH_RECORDS_SUCCESS = 'GET_CONTRACTOR_MONTH_RECORDS_SUCCESS';
export const GET_CONTRACTOR_MONTH_RECORDS_FAILED = 'GET_CONTRACTOR_MONTH_RECORDS_FAILED';
export const ADD_CLIENT = 'ADD_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const ADD_CLIENT_CURRENCY = 'ADD_CLIENT_CURRENCY';
export const DELETE_CLIENT_CURRENCY = 'DELETE_CLIENT_CURRENCY';
export const ADD_CONTRACTOR_DOCUMENT = 'ADD_CONTRACTOR_DOCUMENT';
export const SET_REVOLUT_AUTH_DATA = 'SET_REVOLUT_AUTH_DATA';
export const SET_SELECTED_CANDIDATE = 'SET_SELECTED_CANDIDATE';
export const EDIT_SELECTED_CANDIDATE = 'EDIT_SELECTED_CANDIDATE';
export const EDIT_SELECTED_CANDIDATE_SUCCESS = 'EDIT_SELECTED_CANDIDATE_SUCCESS';
export const EDIT_SELECTED_CANDIDATE_FAILED = 'EDIT_SELECTED_CANDIDATE_FAILED';