// @flow

import { combineReducers } from 'redux';

import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import AdminDashboard from './adminView/reducers';

const rootReducer = combineReducers({
    Auth,
    AppMenu,
    Layout,
    AdminDashboard,
});
export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>