import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { Card, CardBody } from 'reactstrap';

import { dateStrFormatter } from "../../../helpers";
import { JobType } from "../../../types";

const columns = [
	{ dataField: 'job_title_action', text: 'Job Title', sort: true, footer: ''},
	{ dataField: 'contact_full_name', text: 'Contact', sort: true, footer: ''},
	{ dataField: 'client.client_name', text: 'Client', sort: true, footer: ''},
	{ dataField: 'sub_category.sub_category_name', text: 'Subcategory', sort: true, footer: ''},
	{ dataField: 'expiry_date', text: 'Expiring date', sort: true, footer: '', formatter: dateStrFormatter},
	{ dataField: 'job_summary', text: 'Summary', sort: false, footer: ''},
	{ dataField: 'actions', text: '', sort: false, footer: '', classes: "d-flex" },
];
type Props = {
	jobs: JobType[];
};
const JobsGrid = ({ jobs }: Props) => {
    return(
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="table-responsive mainJobsTable">
                        <BootstrapTable
                            keyField="job_id"
                            data={jobs}
                            columns={columns}
                        />
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default JobsGrid;