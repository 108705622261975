// @flow
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';

import * as layoutConstants from '../constants/layout';
import { RootState } from '../redux/reducers';
import { UserType } from '../types';

// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;

// All layouts/containers
const AuthLayout = React.lazy(() => import('../layouts/Auth'));
const VerticalLayout = React.lazy(() => import('../layouts/Vertical'));
const HorizontalLayout = React.lazy(() => import('../layouts/Horizontal'));

type Props = {
	user: UserType | null;
	layout: any
};

/**
 * Exports the component with layout wrapped to it
 * @param {} WrappedComponent
 */
const withLayout = (WrappedComponent: React.ElementType) => {
    const HOC = class extends Component<Props> {
        /**
         * Returns the layout component based on different properties
         */
        getLayout = () => {
            if (!this.props.user){
				return AuthLayout;
			}
            let layoutCls:React.ElementType = VerticalLayout;

            switch (this.props.layout.layoutType) {
                case layoutConstants.LAYOUT_HORIZONTAL:
                    layoutCls = HorizontalLayout;
                    break;
                default:
                    layoutCls = VerticalLayout;
                    break;
            }
            return layoutCls;
        };

        render() {
            const Layout = this.getLayout();
            return (
                <Suspense fallback={loading()}>
                    <Layout {...this.props}>
                        <WrappedComponent {...this.props} />
                    </Layout>
                </Suspense>
            );
        }
    };

    const mapStateToProps = (state: RootState) => {
        return {
            layout: state.Layout,
			user: state.Auth.user,
        };
    };

    return connect(
        mapStateToProps,
        null
    )(HOC);
};

export default withLayout;
