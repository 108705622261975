// @flow
import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';

import contractorViewSaga from './contractorView/saga';
import adminViewSaga from './adminView/saga';

export default function* rootSaga(getState: any): any {
    yield all([
        authSaga(), 
        layoutSaga(), 
        appMenuSaga(),
        contractorViewSaga(),
        adminViewSaga(),
    ]);
}
