import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { RootState } from '../redux/reducers';

import { PagePermission, UserType } from '../types';

type Props = {
	component: React.ElementType;
	roles: String[];
	user: UserType | null;
	role: string;
	isAuthCompleted: boolean;
	rest: any;
	pagePermissions: PagePermission[];
}
const hasPermission = (pagePermissions: PagePermission[], pathname: string) => {
	if(/\d/.test(pathname)){
		for(const permission of pagePermissions){
			const index = permission.page_path.indexOf(':');
			if(index > 0){
				const path = permission.page_path.substring(0, index);
				if(pathname.includes(path)){
					return true;
				}
			}
		}
	} else {
		return pagePermissions.find((permission) => permission.page_path === pathname) ? true : false;
	}
}
const PrivateRoute = ({ component: Component, roles, user, role, isAuthCompleted, pagePermissions, ...rest }: Props) => (
    <Route
        {...rest}
        render={props => {
			if (isAuthCompleted && !user) {
				// not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
			}
            // check if route is restricted by role
            if (isAuthCompleted && roles && roles.indexOf(role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }
			if(isAuthCompleted && !hasPermission(pagePermissions, props.location.pathname)){
				return <p> You dont have permissions to view this page.</p>
			}
            // authorised so return component
            return <Component {...props} />;
        }}
    />
);
const mapStateToProps = (state: RootState) => ({
	user: state.Auth.user,
	role: state.Auth.role,
	pagePermissions: state.Auth.pagePermissions,
	isAuthCompleted: state.Auth.isAuthCompleted,
})
export default connect(mapStateToProps, { })(PrivateRoute);