import { dashboardAdminRoute,
	createContractorRoute, adminOverViewRoute, changeContractRoute,
	advancedSearchRoute, jobsRoute, recruiterRoutes, clientRoutes,
	expenseRoutes, conciliationRoute, payRecruitersRoute, payContractorsRoute, revolutAccountsRoute, managePaperworkRoute, auditLogRoute, referralRoutes, notificationTypeRoute
} from ".";
import { PAGES } from "../helpers";
import { CustomRoute } from "../types";

const pagePermissionMap = new Map<string, CustomRoute>();
export const navPages = ['/admin/dashboard','/admin/overview', '/admin/changeContract', '/admin/createContractor','/admin/advancedSearch', '/admin/jobs',
	'/admin/recruiter/manage', '/admin/client/manage',
	'/admin/expense/manage', '/admin/paperwork', '/admin/auditlogs', PAGES.REFERRALS_TABLE_PAGE,
	PAGES.NOTIFICATION_TYPES,
];

pagePermissionMap.set("/admin/dashboard", dashboardAdminRoute);
pagePermissionMap.set("/admin/createContractor", createContractorRoute);
pagePermissionMap.set("/admin/overview", adminOverViewRoute);
pagePermissionMap.set("/admin/changeContract", changeContractRoute);
pagePermissionMap.set("/admin/advancedSearch", advancedSearchRoute);
pagePermissionMap.set("/admin/jobs", jobsRoute);

pagePermissionMap.set("/admin/recruiter/manage", recruiterRoutes);
pagePermissionMap.set("/admin/recruiter/invoices", recruiterRoutes);

pagePermissionMap.set("/admin/client/manage", clientRoutes);
pagePermissionMap.set(PAGES.CLIENT_INVOICES_PAGE, clientRoutes);

pagePermissionMap.set("/admin/expense/manage", expenseRoutes);
pagePermissionMap.set("/admin/expense/payMethods", expenseRoutes);
pagePermissionMap.set("/admin/expense/categories", expenseRoutes);

pagePermissionMap.set("/admin/conciliation", conciliationRoute);
pagePermissionMap.set("/admin/revolut/payRecruiters", payRecruitersRoute);
pagePermissionMap.set("/admin/revolut/payContractors", payContractorsRoute);
pagePermissionMap.set("/admin/revolut/accounts", revolutAccountsRoute);

pagePermissionMap.set("/admin/paperwork", managePaperworkRoute);
pagePermissionMap.set("/admin/auditlogs", auditLogRoute);
pagePermissionMap.set(PAGES.NOTIFICATION_TYPES, notificationTypeRoute);
pagePermissionMap.set(PAGES.REFERRALS_TABLE_PAGE, referralRoutes);
pagePermissionMap.set("/referrals/create", referralRoutes);
pagePermissionMap.set("/referrals/:id", referralRoutes);

// /pages/error-404
// /account/login
// /account/logout
// /linkedinCallback

export default pagePermissionMap;