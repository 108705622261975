import {
    GET_ALL_CONTRACTORS, GET_ALL_CONTRACTORS_ERROR, GET_ALL_CONTRACTORS_SUCCESS, 
    GET_CLIENTS, GET_CLIENTS_ERROR, GET_CLIENTS_SUCCESS,
    GET_POSSIBLE_INVOICE_STATUSES, GET_POSSIBLE_INVOICE_STATUSES_SUCCESS, GET_POSSIBLE_INVOICE_STATUSES_ERROR, 
    UPDATE_CONTRACTOR_MONTH_RECORD, 
    INIT_MONTH_END, INIT_MONTH_END_SUCCESS, INIT_MONTH_END_FAILED, 
    GET_EXCHANGE_RATES, GET_EXCHANGE_RATES_FAILED, GET_EXCHANGE_RATES_SUCCESS, 
    UPDATE_EXCHAGE_RATE, UPDATE_EXCHAGE_RATE_SUCCESS, UPDATE_EXCHAGE_RATE_FAILED, 
    INITIAL_LOAD_ADMIN_DB,INITIAL_LOAD_ADMIN_DB_SUCCESS, INITIAL_LOAD_ADMIN_DB_FAILED, GET_CONTRACTOR_MONTH_RECORDS, 
	GET_CONTRACTOR_MONTH_RECORDS_SUCCESS, GET_CONTRACTOR_MONTH_RECORDS_FAILED, ADD_CLIENT, UPDATE_CLIENT, 
	ADD_CLIENT_CURRENCY, DELETE_CLIENT_CURRENCY, ADD_CONTRACTOR_DOCUMENT, SET_REVOLUT_AUTH_DATA, SET_SELECTED_CANDIDATE, EDIT_SELECTED_CANDIDATE, EDIT_SELECTED_CANDIDATE_SUCCESS, EDIT_SELECTED_CANDIDATE_FAILED,
} from "./constants";

export const initialLoadAdminDb = () => ({
    type: INITIAL_LOAD_ADMIN_DB
});
export const initialLoadAdminDbSuccess = (data) => ({
    type: INITIAL_LOAD_ADMIN_DB_SUCCESS,
    payload: data
});
export const initialLoadAdminDbFailed = (error) => ({
    type: INITIAL_LOAD_ADMIN_DB_FAILED,
    payload: error
});

export const getAllContractors = () => ({
    type: GET_ALL_CONTRACTORS,
})
export const getAllContractorsSuccess = (contractors) => ({
    type: GET_ALL_CONTRACTORS_SUCCESS,
    payload: contractors
});
export const getAllContractorsError = (errorMessage) => ({
    type: GET_ALL_CONTRACTORS_ERROR,
    payload: errorMessage
});
export const getClients = () => ({
    type: GET_CLIENTS
});
export const getClientsSuccess = (clients) => ({
    type: GET_CLIENTS_SUCCESS,
    payload: clients
});
export const getClientsError = (error) => ({
    type: GET_CLIENTS_ERROR,
    payload: error
});
export const getPossibleInvoiceStatuses = () => ({
    type: GET_POSSIBLE_INVOICE_STATUSES
});
export const getPossibleInvoiceStatusesSuccess = (invoiceStatuses) => ({
    type: GET_POSSIBLE_INVOICE_STATUSES_SUCCESS,
    payload: invoiceStatuses
});
export const getPossibleInvoiceStatusesError = (error) => ({
    type: GET_POSSIBLE_INVOICE_STATUSES_ERROR,
    payload:error
});
export const updateContractorMonthRecord = (newCmRecord) => ({
    type: UPDATE_CONTRACTOR_MONTH_RECORD,
    payload: newCmRecord
});

export const initMonthEnd = (data) => ({
    type: INIT_MONTH_END,
    payload: data,
});
export const initMonthEndSuccess = (data) => ({
    type: INIT_MONTH_END_SUCCESS,
    payload: data,
});
export const initMonthEndFailed = (errorMessage) => ({
    type: INIT_MONTH_END_FAILED,
    payload: errorMessage,
});

export const getExchangeRates = (year, month) => ({
    type: GET_EXCHANGE_RATES,
	payload: { year, month },
});
export const getExchangeRatesSuccess = ( rates ) => ({
    type: GET_EXCHANGE_RATES_SUCCESS,
    payload: rates
});
export const getExchangeRatesFailed = (error) => ({
    type: GET_EXCHANGE_RATES_FAILED,
    payload: error
});
export const updateExchangeRate = (updatedRate) => ({
    type: UPDATE_EXCHAGE_RATE,
    payload: updatedRate,
});
export const updateExchangeRateSuccess = (updatedRate) => ({
    type: UPDATE_EXCHAGE_RATE_SUCCESS,
    payload: updatedRate,
});
export const updateExchangeRateFailed = (error) => ({
    type: UPDATE_EXCHAGE_RATE_FAILED,
    payload: error,
});

export const getCmRecords = (year, month) => ({
    type: GET_CONTRACTOR_MONTH_RECORDS,
	payload: { year, month },
});
export const getCmRecordsSuccess = (contractorMonthRecords) => ({
    type: GET_CONTRACTOR_MONTH_RECORDS_SUCCESS,
    payload: contractorMonthRecords,
});
export const getCmRecordsFailed = (error) => ({
    type: GET_CONTRACTOR_MONTH_RECORDS_FAILED,
    payload: error
});
export const addClient = (client) => ({
	type: ADD_CLIENT,
	payload: client
});
export const updateClient = (client) => ({
	type: UPDATE_CLIENT,
	payload: client
});
export const addClientCurrency = (clientCurrency) => ({
	type: ADD_CLIENT_CURRENCY,
	payload: clientCurrency,
});
export const deleteClientCurrency = (deletedClientCurrency) => ({
	type: DELETE_CLIENT_CURRENCY,
	payload: deletedClientCurrency,
});
export const addContractorDocument = (contractorDocument) => ({
	type: ADD_CONTRACTOR_DOCUMENT,
	payload: contractorDocument,
});
export const setRevolutAuthData = (revolutAuthData) => ({
	type: SET_REVOLUT_AUTH_DATA,
	payload: revolutAuthData,
});
export const setSelectedCandidate = (candidate) => ({
	type: SET_SELECTED_CANDIDATE,
	payload: candidate,
});
export const editSelectedCandidate = (id, candidate) => ({
	type: EDIT_SELECTED_CANDIDATE,
	payload: {
		id,
		data: candidate,
	},
});
export const editSelectedCandidateSuccess = (newCandidate) => ({
	type: EDIT_SELECTED_CANDIDATE_SUCCESS,
	payload: newCandidate,
});
export const editSelectedCandidateError = (error) => ({
	type: EDIT_SELECTED_CANDIDATE_FAILED,
	payload: error,
});