import React from 'react';
// import { Alert, Button, Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import { Alert, Col, Row } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { connect } from 'react-redux';

import Loader from '../../../components/Loader';
import { defaultQueryConfig, getNotificationTypes } from '../../../helpers/api';
import { RootState } from '../../../redux/reducers';
import { NotificationType } from '../../../types';
import NotificationTypesGrid from '../../components/NotificationTypesGrid';


type Props = {};
const NotificationTypePage = (props: Props) => {
    const { isLoading, error, data, isFetching, refetch } = useQuery<NotificationType[]>(
        ['notificationtypes'],
        () =>
			getNotificationTypes(),
        defaultQueryConfig
    );
    const dataArr: NotificationType[] = Array.isArray(data)
        ? data.map((job) => ({
              ...job,
          }))
        : [];

    return (
        <>
            <Row className="page-title align-items-center">
                <Col xs={12}>
                    <h4 className="mb-1 mt-0">Notification types</h4>
                </Col>
            </Row>
            {/* <Row>
                <Col xs={12}>
                    <Card>
                        <CardBody>
                            
                        </CardBody>
                    </Card>
                </Col>
            </Row> */}
            {error ? (
                <Row>
                    <Col xs={12}>
                        <Alert color="danger">There was an error, please reload the page</Alert>
                    </Col>
                </Row>
            ) : null}
            <Row>
                <Col xs={12}>
                    {(isFetching || isLoading) && <Loader />}
                    <NotificationTypesGrid data={dataArr} refetch={refetch}/>
                </Col>
            </Row>
        </>
    );
};
const mapStateToProps = (state: RootState) => {
    return {};
};
export default connect(mapStateToProps, {})(NotificationTypePage);
